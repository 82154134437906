import React, { useEffect } from "react";
import "./user-list.css";
import CustomButton from "../../../components/general/button/button";
import { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { BsFillCaretDownFill } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import usePopup from "../../../components/general/Popup/usePopup";
import { ListSkeleton } from "../../../components/general/cardSkeleton";
import {
  selectProfile,
  selectTotalUsers,
  selectUsers,
} from "../../../redux/appSlice";
import { useSelector } from "react-redux";
import useAdminFunction from "../hooks/useAdminFunction";
import Pagination from "react-custom-pagination";
import { getUser } from "../../../utils/helpers";
import EmptyState from "../../../assets/icons/emptyState.json";
import Lottie from "lottie-react";

const UserList = () => {
  const data = useSelector(selectUsers);
  const totalUsers = useSelector(selectTotalUsers);
  const userProfile = getUser();

  const { state, dispatch, removeUser } = useAdminFunction();
  const { isLoading, filter, batch, unauthorized } = state;
  const {
    handleCreateUser,
    handleAdminChangePassword,
    handleAdminAccount,
    handleWarning,
  } = usePopup();

  const [currentPage, setCurrentPage] = useState(batch);
  const [postsPerPage] = useState(30);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (number) => {
    dispatch({ batch: number });
  };

  useEffect(() => {
    document.title = "SABC | Team";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cl-page">
      <div className="page-content">
        <div className="page-header">
          <div className="cl-h-box">
            <div className="head">Team</div>
            <div className="header">
              <div className="search-bar">
                <input
                  placeholder="Search by username or role"
                  value={filter}
                  onChange={(e) => dispatch({ filter: e.target.value })}
                />
                <span className="search-icon">
                  <HiOutlineSearch />
                </span>
              </div>
              {userProfile?.role !== "MANAGER" && (
                <div className="btn-proj">
                  <CustomButton onClick={() => handleCreateUser()}>
                    <span className="plus">
                      <AiOutlinePlus />
                    </span>
                    &nbsp; &nbsp; New Member
                  </CustomButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="body-box">
            <div className="body-table">
              <div className="table-head">
                <span>Name</span>
                <span>Email</span>
                <span>Role</span>
                <span>Date Created</span>
              </div>
              {!!isLoading ? (
                <ListSkeleton lists={6} />
              ) : currentPosts.length > 0 ? (
                currentPosts?.map((item) => {
                  let dateString = item?.createdAt;
                  let date = new Date(dateString);
                  let created = date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });

                  return (
                    <div className="table-row" key={item?.email}>
                      <span>
                        {item?.firstName} {item?.lastName}
                      </span>
                      <span>{item?.email}</span>
                      <span>{item?.role}</span>
                      <span>{created}</span>
                      <span className="row-action">
                        {userProfile?.role === "MANAGER" &&
                          userProfile?.id === item?.id && (
                            <span className="dropdown">
                              <BsFillCaretDownFill />
                              <div className="dropdown-content">
                                <span onClick={() => handleAdminAccount(item)}>
                                  View
                                </span>
                              </div>
                            </span>
                          )}
                        {userProfile?.role === "ADMIN" && (
                          <span className="dropdown">
                            <BsFillCaretDownFill />
                            <div className="dropdown-content">
                              <span onClick={() => handleAdminAccount(item)}>
                                View
                              </span>
                              {item?.role === "MANAGER" && (
                                <span
                                  onClick={() =>
                                    handleWarning(item?.id, removeUser)
                                  }
                                >
                                  Delete
                                </span>
                              )}
                            </div>
                          </span>
                        )}
                        {userProfile?.role === "SUPERADMIN" && (
                          <span className="dropdown">
                            <BsFillCaretDownFill />
                            <div className="dropdown-content">
                              <span onClick={() => handleAdminAccount(item)}>
                                View
                              </span>
                              {item?.role !== "SUPERADMIN" && (
                                <span
                                  onClick={() =>
                                    handleWarning(item?.id, removeUser)
                                  }
                                >
                                  Delete
                                </span>
                              )}
                            </div>
                          </span>
                        )}
                      </span>
                    </div>
                  );
                })
              ) : (
                <div className="no-data" style={{ width: "auto" }}>
                  <Lottie animationData={EmptyState} />
                  {unauthorized
                    ? "You are not authorized to view"
                    : "No Team Created"}
                </div>
              )}
            </div>
          </div>
          <div className="pagination">
            {currentPosts.length > 0 && (
              <Pagination
                totalPosts={totalUsers}
                postsPerPage={postsPerPage}
                paginate={paginate}
                view={5}
                showLast={true}
                showFirst={true}
                showIndex={true}
                bgColor="transparent"
                color="#002C5F"
                boxRadius="10%"
                boxHeight="30px"
                boxWidth="30px"
                indexbgColor="#f3f3f3"
                selectColor="#d0d0d0"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
