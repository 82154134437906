import { configureStore } from "@reduxjs/toolkit";
import imageListReducer from "./imageSlice";
import activityListReducer from "./activitySlice";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import modalReducer from "./modalSlice";
import appReducer from "./appSlice";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: {
    imageList: imageListReducer,
    activityList: activityListReducer,
    modal: modalReducer,
    app: appReducer,
  },
  middleware: customizedMiddleware,
});
