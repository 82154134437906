import React from "react";
import "./login.css";

import { FormInput, FormPassInput } from "../../general/form-input/form-input";
import CustomButton from "../../general/button/button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useAuthentication from "../../../pages/client/hooks/useAuthentication";

const Login = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const { state, handleLogin, dispatch } = useAuthentication();

  const { email, password, isLoading } = state;

  return (
    <div className="login-section">
      <span className="login-header">Welcome back, Log In</span>

      <form onSubmit={handleLogin} className="login-form">
        <FormInput
          label="Email Address"
          name="email"
          value={email}
          handleChange={(e) => dispatch({ email: e.target.value })}
          type="email"
        />

        <FormPassInput
          name="password"
          value={password}
          label="Password"
          type={show ? "text" : "password"}
          show={show}
          setShow={setShow}
          handleChange={(e) => dispatch({ password: e.target.value })}
        />

        <span className="f-pass" onClick={() => navigate("/forgot")}>
          Forgot password ?
        </span>

        <CustomButton type="submit" loading={!!isLoading}>
          Login
        </CustomButton>
      </form>
    </div>
  );
};

export default Login;
