export const roles = [
  { label: "Admin", value: "ADMIN" },
  { label: "Manager", value: "MANAGER" },
];

export const stages = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  {
    label: "Completed",
    value: "completed",
  },
];
