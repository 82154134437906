import React from "react";
import { useDispatch } from "react-redux";
import { showPopup } from "../../../redux/modalSlice";
import AdminAccount from "../../admin/admin-account/admin-account";
import AdminChangePassword from "../../admin/admin-change-password/admin-change-password";
import EditManager from "../../admin/EditManager";
import ManageProgress from "../../admin/ManageProgress";
import EditActivity from "../../admin/new-activity/edit-activity";
import NewActivity from "../../admin/new-activity/new-activity";
import NewClient from "../../admin/new-client/new-client";
import NewProject from "../../admin/NewProject";
import Upload from "../../admin/Upload";
import Warning from "../../admin/WarningMe/WarningMe";
import ChangePassword from "../../client/change-password/change-password";
import CommentPop from "../../client/comment/comment";
import MyAccount from "../../client/my-account/my-account";
import EditForm from "../../EditForm/EditForm";

function usePopup() {
  const dispatch = useDispatch();

  function handleMyAccount() {
    dispatch(showPopup(<MyAccount />));
  }
  function handleChangePassword() {
    dispatch(showPopup(<ChangePassword />));
  }
  function handleAdminAccount(item) {
    dispatch(showPopup(<AdminAccount item={item} />));
  }
  function handleProjectDetails(item) {
    dispatch (showPopup(<EditForm item={item}/>))
  }
  function handleCreateUser() {
    dispatch(showPopup(<AdminAccount isUser />));
  }
  function handleAdminChangePassword() {
    dispatch(showPopup(<AdminChangePassword />));
  }
  function handleNewClient() {
    dispatch(showPopup(<NewClient />));
  }
  function handleNewActivity(id) {
    dispatch(showPopup(<NewActivity id={id} />));
  }
  function handleNewUpload(id) {
    dispatch(showPopup(<Upload id={id} />));
  }
  function handleComment(id) {
    dispatch(showPopup(<CommentPop id={id} />));
  }
  function handleEditActivity(task) {
    dispatch(showPopup(<EditActivity task={task} />));
  }
  function handleNewProject() {
    dispatch(showPopup(<NewProject />));
  }
  function handleManageProgress(id, level) {
    dispatch(showPopup(<ManageProgress id={id} level={level} />));
  }
  function handleEditManager(id) {
    dispatch(showPopup(<EditManager id={id} />));
  }
  function handleEditClientsDetails(project) {
    dispatch(showPopup(<EditForm  project={project} />));
  }

  function handleWarning(id, fnc, item) {
    dispatch(showPopup(<Warning id={id} fnc={fnc} item={item} />));
  }
  return {
    handleMyAccount,
    handleChangePassword,
    handleNewClient,
    handleNewActivity,
    handleAdminAccount,
    handleAdminChangePassword,
    handleNewUpload,
    handleComment,
    handleEditActivity,
    handleNewProject,
    handleManageProgress,
    handleCreateUser,
    handleEditManager,
    handleWarning,
    handleEditClientsDetails,
    handleProjectDetails,
  };
}

export default usePopup;
