import React, { useEffect } from "react";
import Footer from "../components/general/footer/footer";
import Navbar from "../components/client/navbar/navbar";
import { getToken } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

const ClientLayout = ({ children }) => {
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default ClientLayout;
