import React, { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../general/button/button";
import { FormPassInput } from "../../general/form-input/form-input";
import "./reset-password.css";
import { BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import useAdminAuthentication from "../../../pages/admin/hooks/useAdminAuthentication";

const ResetPassword = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const otpValue = otp.join("");

  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location?.state?.resetEmail;

  const { state, handleResetPassword, dispatch } = useAdminAuthentication();
  const { isLoading, password, cPassword } = state;

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    dispatch({ verifyPin: otpValue });
  }, [otpValue]);

  return (
    <div className="reset-password-section">
      <span className="password-header">Congratulations !!</span>
      <span className="password-sub-head">
        A confirmation code has been sent to <span>{locationState}</span>.{" "}
        <br />
        Copy your activation code and paste in the box below.
      </span>
      <form
        onSubmit={(e) => handleResetPassword(e, locationState)}
        className="login-form"
      >
        <div className="otp-field">
          {otp.map((data, index) => {
            return (
              <input
                className="otp-input"
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
              />
            );
          })}
        </div>
        <FormPassInput
          label="New Password"
          type={show ? "text" : "password"}
          show={show}
          setShow={setShow}
          name="password"
          value={password}
          handleChange={(e) => dispatch({ password: e.target.value })}
        />

        <FormPassInput
          label="Confirm Password"
          type={show2 ? "text" : "password"}
          show={show2}
          setShow={setShow2}
          name="confirmPassword"
          value={cPassword}
          handleChange={(e) => dispatch({ cPassword: e.target.value })}
        />
        <div className="btn-group">
          <CustomButton type="submit" loading={!!isLoading}>
            Verify
          </CustomButton>

          <button className="btn" onClick={() => navigate("/admin")}>
            <span className="arrow">
              <BsArrowLeft />
            </span>
            <span>Back to Log In</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
