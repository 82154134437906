import React, { useEffect } from "react";
import "../login/login.css";
import LogoImage from "../../../assets/images/logo.png";
import ResetPassword from "../../../components/client/reset-password/reset-password";
import BasicSlider from "../../../components/general/basicSlider/basicSlider";

const ResetPage = () => {
  useEffect(() => {
    document.title = "SABC | Password_Reset";
  }, []);

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="content">
          <div className="content-logo">
            <img src={LogoImage} alt="logo" />
          </div>
          <div className="content-body">
            <ResetPassword />
          </div>
        </div>
        <BasicSlider />
      </div>
    </div>
  );
};

export default ResetPage;
