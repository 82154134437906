import React from "react";
import "./form-input.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";

export const FormInput = ({ label, handleChange, ...otherProps }) => {
  return (
    <div className="form-input">
      <input
        className="input-field"
        onChange={handleChange}
        {...otherProps}
        required
      />
      <span className="input-label">{label}</span>
    </div>
  );
};

export const FormPassInput = ({
  label,
  handleChange,
  show,
  setShow,
  error,
  ...otherProps
}) => {
  return (
    <div className="form-input">
      <input
        className="input-field"
        onChange={handleChange}
        {...otherProps}
        required
      />
      <span className="input-label">{label}</span>
      <span className="eye" onClick={() => setShow(!show)}>
        {show ? <BsEyeSlash /> : <BsEye />}
      </span>
      <span className="error-msg">{error}</span>
    </div>
  );
};

export const FormProfileInput = ({
  placeholder,
  label,
  handleChange,
  ...otherProps
}) => {
  return (
    <div className="form-profile-input">
      <span className="profile-input-label">{label}</span>
      <input
        className="profile-input-field"
        placeholder={placeholder}
        onChange={handleChange}
        {...otherProps}
        required
      />
    </div>
  );
};
