import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    projects: [],
    clients: [],
    project: {},
    users: [],
    totalProjects: "",
    totalClients: "",
    totalUsers: "",
    profile: {},
    isLoading: false,
  },

  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setTotalProjects: (state, action) => {
      state.totalProjects = action.payload;
    },
    setTotalClients: (state, action) => {
      state.totalClients = action.payload;
    },
    setTotalUsers: (state, action) => {
      state.totalUsers = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setProjects,
  setClients,
  setUsers,
  setTotalProjects,
  setTotalClients,
  setTotalUsers,
  setProject,
  setProfile,
  setIsLoading,
} = appSlice.actions;

export const selectProjects = (state) => state.app.projects;
export const selectProject = (state) => state.app.project;
export const selectClients = (state) => state.app.clients;
export const selectUsers = (state) => state.app.users;
export const selectTotalProjects = (state) => state.app.totalProjects;
export const selectTotalClients = (state) => state.app.totalClients;
export const selectTotalUsers = (state) => state.app.totalUsers;
export const selectProfile = (state) => state.app.profile;
export const selectIsLoading = (state) => state.app.isLoading;

export default appSlice.reducer;
