import axios from "axios";
import env from "../env";
import { request } from "./api";

const API_URL = `${env.API_URL}`;

const URL = {
  getProjects: (search, batch, limit) =>
    `${API_URL}/admin/project?search=${search}&batch=${batch}&limit=${limit}`,
  getClients: (search, batch, limit) =>
    `${API_URL}/admin/client?search=${search}&batch=${batch}&limit=${limit}`,
  getUsers: (search, batch, limit) =>
    `${API_URL}/admin/users?search=${search}&batch=${batch}&limit=${limit}`,
  createClient: `${API_URL}/admin/client`,
  getProjectsDetails: `${API_URL}/admin/project`,
  createAdmin: `${API_URL}/admin`,
  createProject: `${API_URL}/project`,
  changePassword: `${API_URL}/admin/password`,
  updateActivity: `${API_URL}/project/activity`,
  addMedia: `${API_URL}/project/media`,
  editManager: `${API_URL}/project/manager`,
  manageProgress: `${API_URL}/project/progress`,
  updateMe: `${API_URL}/admin/me`,
  editProjectDetails: `${API_URL}/admin/project`
};

const getProjects = (token, search, batch, limit = 30) => {
  return request(
    URL.getProjects(search, batch, limit),
    {},
    "GET",
    `Bearer ${token}`
  );
};
const getClients = (token, search, batch, limit = 30) => {
  return request(
    URL.getClients(search, batch, limit),
    {},
    "GET",
    `Bearer ${token}`
  );
};
const getUsers = (token, search, batch, limit = 30) => {
  return request(
    URL.getUsers(search, batch, limit),
    {},
    "GET",
    `Bearer ${token}`
  );
};
const getProjectsDetails = (token, id) => {
  return request(
    `${URL.getProjectsDetails}/${id}`,
    {},
    "GET",
    `Bearer ${token}`
  );
};

const createClient = (
  token,
  password,
  phone,
  firstName,
  lastName,
  address,
  email,
  exist
) => {
  return axios({
    method: "POST",
    url: URL.createClient,
    data: { password, phone, firstName, lastName, address, email, exist },
    headers: { authorization: `Bearer ${token}` },
  });
  // return request(
  //   URL.createClient,
  //   { password, phone, firstName, lastName, address, email, exist },
  //   "POST",
  //   `Bearer ${token}`
  // );
};
const createAdmin = (
  token,
  password,
  phone,
  firstName,
  lastName,
  email,
  role,
  exist
) => {
  return axios({
    method: "POST",
    url: URL.createAdmin,
    data: { password, phone, firstName, lastName, email, role, exist },
    headers: { authorization: `Bearer ${token}` },
  });
  // return request(
  //   URL.createAdmin,
  //   { password, phone, firstName, lastName, email, role, exist },
  //   "POST",
  //   `Bearer ${token}`
  // );
};
const changePassword = (token, password, newPassword, confirmPassword) => {
  return request(
    URL.changePassword,
    { password, newPassword, confirmPassword },
    "POST",
    `Bearer ${token}`
  );
};
const createProject = (token, formData) => {
  return axios({
    method: "POST",
    url: URL.createProject,
    data: formData,
    headers: { authorization: `Bearer ${token}` },
  });
};
const removeProject = (token, id) => {
  return request(`${URL.createProject}/${id}`, {}, "DELETE", `Bearer ${token}`);
};
const removeUser = (token, id) => {
  return request(`${URL.createAdmin}/${id}`, {}, "DELETE", `Bearer ${token}`);
};

const updateActivity = (token, id, activity) => {
  return request(
    `${URL.updateActivity}/${id}`,
    { activity },
    "PATCH",
    `Bearer ${token}`
  );
};
const removeActivity = (token, id, activity) => {
  return request(
    `${URL.updateActivity}/${id}`,
    { activity },
    "DELETE",
    `Bearer ${token}`
  );
};
const addMedia = (token, id, formData) => {
  return axios({
    method: "PATCH",
    url: `${URL.addMedia}/${id}`,
    data: formData,
    headers: { authorization: `Bearer ${token}` },
  });
};
const deleteMedia = (token, id) => {
  return request(`${URL.addMedia}/${id}`, {}, "DELETE", `Bearer ${token}`);
};
const editManager = (token, id, manager) => {
  return request(
    `${URL.editManager}/${id}`,
    { manager },
    "PATCH",
    `Bearer ${token}`
  );
};
const manageProgress = (token, id, progress) => {
  return request(
    `${URL.manageProgress}/${id}`,
    { progress },
    "PATCH",
    `Bearer ${token}`
  );
};
const deleteClient = (
  token,
  id,
  password,
  phone,
  firstName,
  lastName,
  address,
  email
) => {
  return request(
    `${URL.createClient}/${id}`,
    { password, phone, firstName, lastName, address, email },
    "DELETE",
    `Bearer ${token}`
  );
};
const updateMe = (token, firstName, lastName, phone) => {
  return request(
    URL.updateMe,
    { firstName, lastName, phone },
    "PATCH",
    `Bearer ${token}`
  );
};

const updateProfile = (token, id, firstName, lastName, phone, role) => {
  return request(
    `${URL.createAdmin}/${id}`,
    { firstName, lastName, phone, role },
    "PATCH",
    `Bearer ${token}`
  );
  }
  const editProjectDetails = (token, id, clientId, projectName, location) => {
    return request(
      `${URL.editProjectDetails}/${id}`,
      { projectName, location, clientId},
      "PATCH",
      `Bearer ${token}`
    );
};

const adminService = {
  getProjects,
  getClients,
  getProjectsDetails,
  createClient,
  createAdmin,
  changePassword,
  getUsers,
  createProject,
  updateActivity,
  addMedia,
  deleteMedia,
  editManager,
  manageProgress,
  deleteClient,
  removeProject,
  removeUser,
  removeActivity,
  updateMe,
  updateProfile,
  editProjectDetails
};

export default adminService;
