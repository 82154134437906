import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../components/admin/navbar/navbar';
import Footer from '../components/general/footer/footer';
import { getToken } from '../utils/helpers';

export const AdminLayout = ({ children }) => {
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/admin');
    }
  }, []);

  return (
    <div>
      <AdminNavbar />
      {children}
      <Footer />
    </div>
  );
};

export const AdminLayout2 = ({ children }) => {
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/admin');
    }
  }, []);
  return (
    <div>
      <AdminNavbar />
      {children}
    </div>
  );
};
