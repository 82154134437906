import React, { useState } from "react";
import "./DropDown.styles.css";

function DropDown({ options, onChange, value }) {
  const [isActive, setIsActive] = useState(false);
  const [isSelect, setIsSelect] = useState("");
  const [search, setSearch] = useState("");

  const handleFilter = (items, filter) => {
    return items.filter((item) =>
      item?.label.toLowerCase().includes(filter?.toLowerCase())
    );
  };

  const filteredOptions = handleFilter(options, search);

  return (
    <div className={isActive ? "select-box active" : "select-box"}>
      <div className="select-option" onClick={() => setIsActive(!isActive)}>
        <input
          type="text"
          placeholder="Select..."
          id="soValue"
          readOnly
          name=""
          value={isSelect || value || ""}
        />
      </div>
      <div className="content">
        <div className="search">
          <input
            type="text"
            id="optionSearch"
            placeholder="Search"
            name=""
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <ul className="options">
          {filteredOptions?.map((itm) => {
            return (
              <li
                onClick={() => {
                  setIsActive(false);
                  setIsSelect(itm.label);
                  onChange(itm.value);
                }}
              >
                {itm.label}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
    // <div className="container">
    //   <div className="select-box" onClick={() => setIsActive(!isActive)}>
    //     <div
    //       className={
    //         isActive ? "options-container active" : "options-container"
    //       }
    //     >
    //       {options?.map((itm) => {
    //         return (
    //           <div
    //             className="option"
    //             onClick={() => {
    //               setIsActive(false);
    //               setIsSelect(itm.label);
    //               onChange(itm.value);
    //             }}
    //           >
    //             <input type="radio" className="radio" id={itm.value} />
    //             <label for={itm.value} onClick={() => setIsActive(false)}>
    //               {itm.label}
    //             </label>
    //           </div>
    //         );
    //       })}
    //     </div>
    //     <div className="selected">
    //       {isSelect}{" "}
    //       <MdOutlineKeyboardArrowDown
    //         className={isActive ? "selected-icon-active" : "selected-icon"}
    //       />
    //     </div>
    //   </div>
    // </div>
  );
}

export default DropDown;
