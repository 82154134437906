import './App.css';

import AdminLoginPage from './pages/admin/login/login';
import AdminResetPage from './pages/admin/reset/reset';
import AdminForgotPage from './pages/admin/forgot/forgot';
import LoginPage from './pages/client/login/login';
import ForgotPage from './pages/client/forgot/forgot';
import ResetPage from './pages/client/reset/reset';
import Dashboard from './pages/client/dashboard';
import ProjectDetails from './pages/client/projectDetails/projectDetails';
import ClientLayout from './layout/client-layout';
import { AdminLayout, AdminLayout2 } from './layout/admin-layout';
import ProjectDashboard from './pages/admin/project-dashboard/project-dashboard';
import AdminDashboard from './pages/admin/admin-dashboard/admin-dashboard';
import ClientDashboard from './pages/admin/client-dashboard/client-dashboard';
import ClientList from './pages/admin/client-list/client-list';
import { Route, Routes } from 'react-router-dom';
import UserList from './pages/admin/user-list/user-list';
import AdminProjectDetails from './pages/admin/admin-projectDetails/admin-projectDetails';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux';
import { selectModal, selectPopup } from './redux/modalSlice';
import Popup from './components/general/Popup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const isModalOpen = useSelector(selectPopup);
  const modal = useSelector(selectModal);

  return (
    <div className="App">
      {isModalOpen && <Popup>{modal}</Popup>}

      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route
          exact
          path="/dashboard"
          element={
            <ClientLayout>
              <Dashboard />
            </ClientLayout>
          }
        />
        <Route
          path="/dashboard/:projectId"
          element={
            <ClientLayout>
              <ProjectDetails />
            </ClientLayout>
          }
        />
        <Route exact path="/forgot" element={<ForgotPage />} />
        <Route exact path="/reset" element={<ResetPage />} />
        <Route exact path="/admin" element={<AdminLoginPage />} />
        <Route exact path="/admin/forgot" element={<AdminForgotPage />} />
        <Route exact path="/admin/reset" element={<AdminResetPage />} />
        <Route
          exact
          path="/admin/dashboard"
          element={
            <AdminLayout2>
              <AdminDashboard />
            </AdminLayout2>
          }
        />
        <Route
          exact
          path="/admin/project"
          element={
            <AdminLayout>
              <ProjectDashboard />
            </AdminLayout>
          }
        />
        <Route
          exact
          path="/admin/project/:projectId"
          element={
            <AdminLayout>
              <AdminProjectDetails />
            </AdminLayout>
          }
        />
        <Route
          exact
          path="/admin/client"
          element={
            <AdminLayout>
              <ClientList />
            </AdminLayout>
          }
        />
        <Route
          exact
          path="/admin/client/:clientId"
          element={
            <AdminLayout>
              <ClientDashboard />
            </AdminLayout>
          }
        />
        <Route
          exact
          path="/admin/users"
          element={
            <AdminLayout>
              <UserList />
            </AdminLayout>
          }
        />
      </Routes>
      <ToastContainer autoClose={2500} pauseOnHover={false} />
    </div>
  );
}

export default App;
