import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import ReactPlayer from "react-player";
import useAdminFunction from "../../../pages/admin/hooks/useAdminFunction";
import { selectIsLoading } from "../../../redux/appSlice";
import CustomButton from "../../general/button/button";
import { useSelector } from "react-redux";
import "../new-upload/new-upload.css";

function Upload({ id }) {
  const [files, setFiles] = useState([]);

  const { addMedia, dispatch } = useAdminFunction();

  const isLoading = useSelector(selectIsLoading);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: "image/*, video/*",
    onDrop: (acceptedFiles) => {
      setFiles([...acceptedFiles]);

      dispatch({ media: acceptedFiles });
    },
  });

  function clearFiles() {
    setFiles(null);
  }

  return (
    <div className="upload-box">
      <div className="header">Upload your images/videos</div>
      <div className="sub">PNG, JPG, GIF and MP4 files are allowed</div>
      <form onSubmit={(e) => addMedia(e, id)} className="upload-form">
        <div className="uploader">
          <div {...getRootProps()} className="uploader-dis">
            <input {...getInputProps()} />
            {!files?.length && (
              <div className="uploader-icon">
                <FiUploadCloud />
                <span className="uploader-text">
                  Drag and drop or browse to choose file
                </span>
              </div>
            )}
            {files?.map((file, index) => {
              const isVideo = file.type.includes("video");
              return (
                <div key={index}>
                  {isVideo ? (
                    <ReactPlayer
                      url={URL.createObjectURL(file)}
                      width="100%"
                      height="100%"
                      controls
                    />
                  ) : (
                    <img src={URL.createObjectURL(file)} alt={file.name} />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="upload-btn">
          <CustomButton loading={isLoading} type="submit">
            Upload
          </CustomButton>
          <CustomButton onClick={() => clearFiles()}>Clear</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default Upload;
