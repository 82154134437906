import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setProfile } from "../../../redux/appSlice";
import authService from "../../../services/auth.service";
import { setToken, setUser } from "../../../utils/helpers";
import { useDispatch } from "react-redux";

function useAdminAuthentication() {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const initState = {
    email: "",
    password: "",
    cPassword: "",
    resetEmail: "",
    verifyPin: "",
    isLoading: false,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initState
  );

  const { email, password, cPassword, resetEmail, verifyPin } = state;

  function getAdminProfile(token) {
    dispatch({ isLoading: true });
    authService
      .getAdminProfile(email, password, token)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }
  function handleAdminLogin(e) {
    e.preventDefault();
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters");
    } else {
      dispatch({ isLoading: true });
      authService
        .adminLogin(email, password)
        .then((response) => {
          toast.success("Successful Login");
          setToken(response.data);
          getAdminProfile(response.data);
          navigate("/admin/dashboard");
        })
        .catch((error) => {
          toast.error("Invalid Credentials");
          console.log(error.message, "inside");
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  }

  function handleForgotPassword(e) {
    e.preventDefault();
    dispatch({ isLoading: true });
    authService
      .adminForgotPassword(resetEmail)
      .then((response) => {
        toast.success("Otp Sent");
        navigate("/admin/reset", { state: { resetEmail } });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Invalid Credentials");
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }

  function handleResetPassword(e, location) {
    e.preventDefault();
    if (password < 6) {
      toast.error("Password must be at least 6 characters");
    } else if (password !== cPassword) {
      toast.error("Passwords do not match");
    } else {
      dispatch({ isLoading: true });
      authService
        .adminResetPassword(verifyPin, password, cPassword, location)
        .then((response) => {
          toast.success("Password Changed");
          navigate("/admin");
        })
        .catch((error) => {
          toast.error("Invalid OTP");
          console.log(error);
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  }

  return {
    state,
    dispatch,
    handleAdminLogin,
    handleForgotPassword,
    handleResetPassword,
  };
}

export default useAdminAuthentication;
