import { createSlice } from "@reduxjs/toolkit";

export const imageSlice = createSlice({
  name: "imageList",
  initialState: {
    imageList: [],
  },

  reducers: {
    populate: (state, action) => {
      state.imageList = action.payload;
    },
    addItem: (state, action) => {
      state.imageList = [...state.imageList, action.payload];
    },
    removeItem: (state, action) => {
      state.imageList = state.imageList.filter(
        (item) => item !== action.payload
      );
    },
  },
});

export const { populate, addItem, removeItem } = imageSlice.actions;

export const selectImageList = (state) => state.imageList.imageList;

export default imageSlice.reducer;
