import React, { useEffect } from "react";

import "./login.css";
import Login from "../../../components/admin/login/login";
import LogoImage from "../../../assets/images/logo.png";

const AdminLoginPage = () => {
  useEffect(() => {
    document.title = "SABC | Admin_Login";
  }, []);

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="content">
          <div className="content-logo">
            <img src={LogoImage} alt="logo" />
          </div>
          <div className="content-body">
            <Login />
          </div>
        </div>
        <div className="image"></div>
      </div>
    </div>
  );
};

export default AdminLoginPage;
