import React, { useEffect, useState } from "react";
import "./admin-dashboard.css";
import ProjectIcon from "../../../assets/icons/blueprint (12 copy.png";
import ClientIcon from "../../../assets/icons/customer2 copy.png";
import CustomButton from "../../../components/general/button/button";
import AdminDashCard from "../../../components/admin/admin-dash-card/admin-dash-card";
import { CardSkeleton } from "../../../components/general/cardSkeleton";
import { useNavigate } from "react-router-dom";
import Pagination from "react-custom-pagination";
import useAdminFunction from "../hooks/useAdminFunction";
import { useSelector } from "react-redux";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  selectProjects,
  selectTotalClients,
  selectTotalProjects,
} from "../../../redux/appSlice";
import { getUser } from "../../../utils/helpers";
import usePopup from "../../../components/general/Popup/usePopup";
import EmptyState from "../../../assets/icons/emptyState.json";
import Lottie from "lottie-react";

const AdminDashboard = () => {
  const navigate = useNavigate();

  const { getProjects, getClients, state, dispatch } = useAdminFunction();
  const { handleNewClient, handleNewProject } = usePopup();
  const { isLoading, batch } = state;
  const totalProjects = useSelector(selectTotalProjects);
  const totalClients = useSelector(selectTotalClients);
  const data = useSelector(selectProjects);

  const [currentPage, setCurrentPage] = useState(batch);
  const [postsPerPage] = useState(30);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (number) => {
    dispatch({ batch: number });
  };

  useEffect(() => {
    document.title = "SABC | Admin_Dashboard";
    window.scrollTo(0, 0);
    getProjects();
  }, [batch]);

  const userProfile = getUser();
  return (
    <div className="admin-dash">
      <div className="dash-page">
        <div className="dash-left">
          <div className="left-analytic">
            <div className="analytic-sub">Welcome to SABC</div>
            <div className="analytic-head">
              Industrial Engineering <br /> and Infrastructure
            </div>
            <div className="analytic-box">
              <div className="analytic-main">
                <div className="main-icon">
                  <img src={ProjectIcon} alt="icon" />
                </div>
                <div className="main-view">
                  <div className="main-main">
                    <div className="main-head">Total Projects</div>
                    <div className="main-metric">
                      {isLoading ? "" : totalProjects}
                    </div>
                  </div>
                </div>
              </div>
              <div className="analytic-main">
                <div className="main-icon">
                  <img src={ClientIcon} alt="icon" />
                </div>
                <div className="main-view">
                  <div className="main-main">
                    <div className="main-head">Total Clients</div>
                    <div className="main-metric">
                      {isLoading ? "" : totalClients}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {userProfile?.role !== "MANAGER" && (
              <div className="btn">
                <CustomButton
                  onClick={() => {
                    navigate("/admin/project");
                    handleNewProject();
                  }}
                >
                  Create Project
                </CustomButton>
                <CustomButton
                  onClick={() => {
                    navigate("/admin/client");
                    handleNewClient();
                  }}
                >
                  Create New Client
                </CustomButton>
              </div>
            )}
            <div className="left-rights">
              Copyright © 2023 SABC Limited. All Rights Reserved.
            </div>
          </div>
        </div>
        <div className="dash-right">
          <div className="dash-body">
            <div className="dash-grid">
              {!!isLoading ? (
                <CardSkeleton cards={4} />
              ) : currentPosts.length > 0 ? (
                currentPosts?.map((item) => (
                  <AdminDashCard
                    url={item?.id}
                    key={item?.id}
                    name={item?.projectName}
                    client={`${item?.client?.firstName} ${item?.client?.lastName}`}
                    progress={item?.progress}
                    picture={item?.media[0]?.url}
                    type={item?.media[0]?.type}
                  />
                ))
              ) : (
                <div className="no-data" style={{ width: "auto" }}>
                  <Lottie animationData={EmptyState} />
                  No Project Created
                </div>
              )}
            </div>
            <div className="pagination">
              {currentPosts.length > 0 && (
                <Pagination
                  totalPosts={totalProjects}
                  postsPerPage={postsPerPage}
                  paginate={paginate}
                  view={5}
                  showLast={true}
                  showFirst={true}
                  showIndex={true}
                  bgColor="transparent"
                  color="#002C5F"
                  indexbgColor="#fff"
                  selectColor="#d0d0d0"
                  boxRadius="10%"
                  boxHeight="30px"
                  boxWidth="30px"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
