import React, { useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import CustomButton from "../../general/button/button";
import { FormProfileInput } from "../../general/form-input/form-input";
import "./NewProject.css";
import useAdminFunction from "../../../pages/admin/hooks/useAdminFunction";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

import DropDown from "../../general/DropDown";
import { selectIsLoading } from "../../../redux/appSlice";

function NewProject() {
  const {
    createProject,
    state,
    dispatch,
    getUsers,
    getClients,
    refactoredClients,
    refactoredManagers,
  } = useAdminFunction();
  const { name, address } = state;
  const isLoading = useSelector(selectIsLoading);
  useEffect(() => {
    getUsers();
    getClients();
  }, []);

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles([...acceptedFiles]);
      dispatch({ media: acceptedFiles });
    },
  });

  const handleClient = (value) => {
    dispatch({ client: value });
  };
  const handleManagers = (value) => {
    dispatch({ manager: value });
  };

  return (
    <div className="np-box">
      <div className="header">
        <div>Upload Your Project Details</div>
      </div>
      <form onSubmit={createProject} className="np-form">
        <div className="uploader">
          <div className="uploader-dis" {...getRootProps()}>
            <input {...getInputProps()} />
            {!files?.length && (
              <div className="uploader-icon">
                <FiUploadCloud />
                <span className="uploader-text">
                  Drag and drop or browse to choose file
                </span>
              </div>
            )}
            {files?.map((file, index) => {
              const isVideo = file.type.includes("video");
              return (
                <div key={index}>
                  {isVideo ? (
                    <ReactPlayer
                      url={URL.createObjectURL(file)}
                      width="100%"
                      height="100%"
                      controls
                    />
                  ) : (
                    <img src={URL.createObjectURL(file)} alt={file.name} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <FormProfileInput
          label="Project Name"
          placeholder="Project Name"
          value={name}
          handleChange={(e) => dispatch({ name: e.target.value })}
        />
        <div className="drop-label">Client Name</div>
        <DropDown onChange={handleClient} options={refactoredClients} />
        <div className="drop-label">Project Manager</div>
        <DropDown onChange={handleManagers} options={refactoredManagers} />
        <FormProfileInput
          label="Location"
          placeholder="Location"
          value={address}
          handleChange={(e) => dispatch({ address: e.target.value })}
        />

        <CustomButton type="submit" loading={!!isLoading}>
          Create Project
        </CustomButton>
      </form>
    </div>
  );
}

export default NewProject;
