import React from "react";
import CustomButton from "../../general/button/button";
import "./new-activity.css";
import { MdOutlineCancel } from "react-icons/md";
import useAdminFunction from "../../../pages/admin/hooks/useAdminFunction";
import ReactDropdown from "react-dropdown";
import { stages } from "../../../pages/admin/hooks/constants";
import { selectIsLoading } from "../../../redux/appSlice";
import { useSelector } from "react-redux";

const EditActivity = ({ setShow, task, id }) => {
  const { state, dispatch, updateActivity } = useAdminFunction();
  const { status } = state;

  const isLoading = useSelector(selectIsLoading);

  return (
    <div className="activity-page edit-page">
      <div className="activity-box">
        <div className="header">Update Activity Status</div>
        <form
          onSubmit={(e) => updateActivity(e, id, setShow)}
          className="activity-form"
        >
          <div className="form-field">
            <label>Activity</label>
            <input name="actTask" value={task} readOnly />
          </div>
          <div className="form-field">
            <label>Status</label>
            <ReactDropdown
              controlClassName="d-dropDown"
              arrowClassName="d-arrow"
              menuClassName="Dropdown-Menu"
              options={stages}
              onChange={(e) => dispatch({ status: e.value, activity: task })}
              value={status}
              placeholder=""
            />
          </div>

          <div className="activity-btn">
            <CustomButton type="submit" loading={!!isLoading}>
              Update activity
            </CustomButton>
          </div>
        </form>
        <div className="activity-close" onClick={() => setShow(false)}>
          <MdOutlineCancel />
        </div>
      </div>
    </div>
  );
};

export default EditActivity;
