import Skeleton from "react-loading-skeleton";

export const CardSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div className="card-skeleton" key={i}>
        <div className="skeleton-img">
          <Skeleton height={285} />
        </div>
        <div className="skeleton-name">
          <Skeleton height={30} />
        </div>
        <div className="skeleton-progress">
          <Skeleton height={45} />
        </div>
      </div>
    ));
};

export const ListSkeleton = ({ lists }) => {
  return Array(lists)
    .fill(0)
    .map((item, i) => (
      <div className="list-skeleton" key={i}>
        <Skeleton height={50} />
      </div>
    ));
};
