import React, { useEffect, useState } from "react";
import "./client-dashboard.css";
import DashCard from "../../../components/client/dash-card/dash-card";
import { CardSkeleton } from "../../../components/general/cardSkeleton";
import Pagination from "react-custom-pagination";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProjects, selectTotalProjects } from "../../../redux/appSlice";
import useAdminFunction from "../hooks/useAdminFunction";
import { getUser } from "../../../utils/helpers";
import CustomButton from "../../../components/general/button/button";
import { AiOutlinePlus } from "react-icons/ai";
import usePopup from "../../../components/general/Popup/usePopup";
import EmptyState from "../../../assets/icons/emptyState.json";
import Lottie from "lottie-react";

const ClientDashboard = () => {
  const { clientId } = useParams();
  const { getProjects, state, dispatch } = useAdminFunction();
  const { handleNewProject } = usePopup();

  const { batch, isLoading } = state;

  const location = useLocation();
  const clientName = location?.state?.clientName;
  const clientEmail = location?.state?.clientEmail;

  const allProjects = useSelector(selectProjects);
  const totalProjects = useSelector(selectTotalProjects);

  const [currentPage, setCurrentPage] = useState(batch);
  const [postsPerPage] = useState(30);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = allProjects?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (number) => {
    dispatch({ batch: number });
  };

  useEffect(() => {
    document.title = "SABC | Client_Dashboard";
    window.scrollTo(0, 0);
    getProjects(clientEmail);
  }, [batch]);

  const userProfile = getUser();

  return (
    <div className="cp-page">
      <div className="page-content">
        <div className="page-header">
          <div className="cp-h-box">
            <div className="cp-head">{clientName}</div>
            <div className="cp-sub">
              {/* Project Manager(s) - <br />
              Adamu Garba, Sherif Adamu */}
            </div>
            <div className="btn-proj">
              {userProfile?.role !== "MANAGER" && (
                <CustomButton onClick={() => handleNewProject()}>
                  <span className="plus">
                    <AiOutlinePlus />
                  </span>{" "}
                  &nbsp; &nbsp; New Project
                </CustomButton>
              )}
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="body-box">
            <div className="body-grid">
              {isLoading ? (
                <CardSkeleton cards={6} />
              ) : currentPosts?.length > 0 ? (
                currentPosts?.map((item) => (
                  <DashCard
                    url_key={item?.id}
                    url_link="/admin/project/"
                    key={item?.id}
                    name={item?.projectName}
                    progress={item?.progress}
                    picture={item?.media[0]}
                  />
                ))
              ) : (
                <div className="no-data" style={{ width: "auto" }}>
                  <Lottie animationData={EmptyState} />
                  No Project Created
                </div>
              )}
            </div>
          </div>
          <div className="pagination">
            {currentPosts?.length > 0 && (
              <Pagination
                totalPosts={totalProjects}
                postsPerPage={postsPerPage}
                paginate={paginate}
                view={5}
                showLast={true}
                showFirst={true}
                showIndex={true}
                bgColor="transparent"
                color="#002C5F"
                indexbgColor="#f3f3f3"
                selectColor="#d0d0d0"
                boxRadius="10%"
                boxHeight="30px"
                boxWidth="30px"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
