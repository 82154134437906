import React from "react";
import "./popup.css";
import { MdOutlineCancel } from "react-icons/md";
import { hidePopup } from "../../../redux/modalSlice";
import { useDispatch } from "react-redux";

function Popup({ children }) {
  const dispatch = useDispatch();
  return (
    <div className="popup-modal">
      <div className="popup-box">
        {children}
        <div className="popup-box-close" onClick={() => dispatch(hidePopup())}>
          <MdOutlineCancel />
        </div>
      </div>
    </div>
  );
}

export default Popup;
