import React from "react";
import { useNavigate } from "react-router-dom";
import "./admin-dash-card.css";
import Poster from "../../../assets/images/VideoPoster.png";

const AdminDashCard = ({ url, progress, name, picture, client, type }) => {
  const navigate = useNavigate();

  return (
    <div className="dash-card">
      <div
        className="dash-card-box"
        onClick={() => navigate("/admin/project/" + url)}
      >
        <div className="dash-card-picture">
          {type?.includes("image") ? (
            <img src={picture} alt="card-img" />
          ) : (
            <video poster={Poster}>
              <source src={picture} />
            </video>
          )}
        </div>
        <div className="dash-card-body">
          <span className="card-body-head">{name}</span>
          <div className="card-body-sub">Client - {client}</div>
          <div className="progress-head">
            <span className="progress">PROGRESS</span>
            <div className="progress-value">{progress}</div>
          </div>
          <div className="progress-bar">
            <div id="mybar" style={{ width: `${Number(progress)}%` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashCard;
