import React, { useEffect } from "react";
import CustomButton from "../../../components/general/button/button";
import { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { BsFillCaretDownFill } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ListSkeleton } from "../../../components/general/cardSkeleton";
import Pagination from "react-custom-pagination";
import usePopup from "../../../components/general/Popup/usePopup";
import { useSelector } from "react-redux";
import {
  selectClients,
  selectProfile,
  selectTotalClients,
} from "../../../redux/appSlice";
import useAdminFunction from "../hooks/useAdminFunction";
import { getUser } from "../../../utils/helpers";
import EmptyState from "../../../assets/icons/emptyState.json";
import Lottie from "lottie-react";

const ClientList = () => {
  const data = useSelector(selectClients);
  const totalClients = useSelector(selectTotalClients);

  const { handleNewClient, handleWarning } = usePopup();
  const { state, deleteClient, dispatch } = useAdminFunction();
  const { isLoading, filter, batch } = state;

  const [currentPage, setCurrentPage] = useState(batch);
  const [postsPerPage] = useState(30);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (number) => {
    dispatch({ batch: number });
  };

  const navigate = useNavigate();
  const userProfile = getUser();

  useEffect(() => {
    document.title = "SABC | Client List";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cl-page">
      <div className="page-content">
        <div className="page-header">
          <div className="cl-h-box">
            <div className="head">Clients</div>
            <div className="header">
              <div className="search-bar">
                <input
                  placeholder="Search by customer name or company name"
                  value={filter}
                  onChange={(e) => dispatch({ filter: e.target.value })}
                />
                <span className="search-icon">
                  <HiOutlineSearch />
                </span>
              </div>
              {userProfile?.role !== "MANAGER" && (
                <div className="btn-proj">
                  <CustomButton onClick={() => handleNewClient()}>
                    <span className="plus">
                      <AiOutlinePlus />
                    </span>
                    &nbsp; &nbsp; New Client
                  </CustomButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="body-box">
            <div className="body-table">
              <div className="table-head">
                <span>Name</span>
                <span>Email</span>
                <span>Phone</span>
                <span>Location / Address</span>
              </div>
              {!!isLoading ? (
                <ListSkeleton lists={6} />
              ) : currentPosts.length > 0 ? (
                currentPosts?.map((item, i) => (
                  <div
                    className="table-row"
                    key={i}
                    // onClick={() =>
                    //   navigate(`/admin/client/${item.id}`, {
                    //     state: {
                    //       clientName: `${item.firstName} ${item.lastName}`,
                    //     },
                    //   })
                    // }
                  >
                    <span>
                      {item?.firstName} {item?.lastName}
                    </span>
                    <span>{item?.email}</span>
                    <span>{item?.phone}</span>
                    <span>{item?.address}</span>
                    <span className="row-action">
                      <span className="dropdown">
                        <BsFillCaretDownFill />

                        <div className="dropdown-content">
                          <span
                            onClick={() =>
                              navigate(`/admin/client/${item?.id}`, {
                                state: {
                                  clientName: `${item?.firstName} ${item?.lastName}`,
                                  clientEmail: `${item?.email}`,
                                },
                              })
                            }
                          >
                            View
                          </span>
                          {userProfile?.role !== "MANAGER" && (
                            <span
                              onClick={() => handleWarning(item, deleteClient)}
                            >
                              Delete
                            </span>
                          )}
                        </div>
                      </span>
                    </span>
                  </div>
                ))
              ) : (
                <div
                  className="no-data"
                  style={{ width: "auto", paddingTop: "0px" }}
                >
                  <Lottie animationData={EmptyState} />
                  No Client Created
                </div>
              )}
            </div>
          </div>
          <div
            className="pagination"
            style={{ width: "fit-content", margin: "auto" }}
          >
            {currentPosts.length > 0 && (
              <Pagination
                totalPosts={totalClients}
                postsPerPage={postsPerPage}
                paginate={paginate}
                view={5}
                showLast={true}
                showFirst={true}
                showIndex={true}
                bgColor="transparent"
                color="#002C5F"
                indexbgColor="#f3f3f3"
                selectColor="#d0d0d0"
                boxRadius="10%"
                boxHeight="30px"
                boxWidth="30px"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientList;
