import React, { useEffect } from "react";
import CustomButton from "../../general/button/button";
import { FormProfileInput } from "../../general/form-input/form-input";
import "./my-account.css";
import usePopup from "../../general/Popup/usePopup";
import useClient from "../../../pages/client/hooks/useClient";
import { getUser } from "../../../utils/helpers";

const MyAccount = () => {
  const { state, dispatch, updateUser } = useClient();
  const { firstName, lastName, phone, email, isLoading } = state;

  const { handleChangePassword } = usePopup();

  const userProfile = getUser();

  useEffect(() => {
    dispatch({
      firstName: userProfile?.firstName,
      lastName: userProfile?.lastName,
      phone: userProfile?.phone,
      email: userProfile?.email,
    });
  }, []);

  return (
    <div className="acc-box">
      <div className="header"></div>

      <form onSubmit={updateUser} className="acc-form">
        <div className="sm">
          <FormProfileInput
            label="First Name"
            className="sm"
            placeholder="First Name"
            value={firstName}
            handleChange={(e) => dispatch({ firstName: e.target.value })}
          />
          <FormProfileInput
            label="Last Name"
            className="sm"
            placeholder="Last Name"
            value={lastName}
            handleChange={(e) => dispatch({ lastName: e.target.value })}
          />
        </div>
        <FormProfileInput
          label="Email Address"
          placeholder="Email Address"
          value={email}
          readOnly
        />
        <FormProfileInput
          label="Phone Number *"
          placeholder="Phone Number"
          value={phone}
          type="number"
          handleChange={(e) => dispatch({ phone: e.target.value })}
        />
        <div className="pass-change" onClick={() => handleChangePassword()}>
          Change Password
        </div>

        <CustomButton type="submit" loading={isLoading}>
          Save Changes
        </CustomButton>
      </form>
    </div>
  );
};

export default MyAccount;
