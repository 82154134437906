import { useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  selectClients,
  selectProfile,
  selectUsers,
  setClients,
  setIsLoading,
  setProject,
  setProjects,
  setTotalClients,
  setTotalProjects,
  setTotalUsers,
  setUsers,
} from "../../../redux/appSlice";
import { hidePopup } from "../../../redux/modalSlice";
import adminService from "../../../services/admin.service";
import { getToken } from "../../../utils/helpers";

function useAdminFunction() {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  const initState = {
    isLoading: false,
    isLoadingBtn: false,
    id: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    phone: "",
    firstName: "",
    lastName: "",
    address: "",
    email: "",
    role: "",
    media: [],
    name: "",
    manager: "",
    client: "",
    activity: "",
    status: "",
    progress: "",
    filter: "",
    batch: 1,
    exist: false,
    unauthorized: false,
    reCall: false,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initState
  );

  const {
    password,
    newPassword,
    confirmPassword,
    phone,
    firstName,
    lastName,
    address,
    email,
    role,
    name,
    client,
    manager,
    media,
    activity,
    status,
    progress,
    filter,
    batch,
    exist,
  } = state;

  const clientsList = useSelector(selectClients);
  const allAdmins = useSelector(selectUsers);
  const userProfile = useSelector(selectProfile);

  // useEffect(() => {
  //   dispatch({ firstName: userProfile.firstName });
  //   dispatch({ lastName: userProfile.lastName });
  //   dispatch({ phone: userProfile.phone });
  //   dispatch({ email: userProfile.email });
  // }, [userProfile]);

  const refactoredClients = useMemo(
    () =>
      clientsList?.map((item) => ({
        // ...item,
        // id: item?.id,
        label: `${item?.firstName} ${item?.lastName}`,
        value: item?.id,
      })),
    [clientsList]
  );
  const refactoredManagers = useMemo(
    () =>
      allAdmins
        .filter((item) => item.role === "MANAGER")
        .map((item) => ({
          // ...item,
          label: `${item?.firstName} ${item?.lastName}`,
          value: item?.id,
        })),
    [allAdmins]
  );

  const getProjects = (search = "") => {
    dispatch({ isLoading: true });
    adminService
      .getProjects(token, search, batch)
      .then((res) => {
        reduxDispatch(setProjects(res.data.data));
        reduxDispatch(setTotalProjects(res.data.total));
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          console.log(err);
        }
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  };

  const handleFilter = (items, filter) => {
    return items.filter(
      (item) =>
        item?.firstName?.toLowerCase().includes(filter?.toLowerCase()) ||
        item?.lastName?.toLowerCase().includes(filter?.toLowerCase()) ||
        item?.role?.toLowerCase().includes(filter?.toLowerCase())
    );
  };

  const getClients = () => {
    dispatch({ isLoading: true });
    adminService
      .getClients(token, filter, batch)
      .then((res) => {
        reduxDispatch(setClients(res.data.data));
        reduxDispatch(setTotalClients(res.data.total));
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          console.log(err);
        }
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  };
  const getUsers = () => {
    dispatch({ isLoading: true });
    adminService
      .getUsers(token, filter, batch)
      .then((res) => {
        reduxDispatch(setUsers(res.data.data));
        reduxDispatch(setTotalUsers(res.data.total));
      })
      .catch((err) => {
        if (err?.message === "Unauthorized Request") {
          dispatch({ unauthorized: true });
        }
        console.log(err);
      })

      .finally(() => {
        dispatch({ isLoading: false });
      });
  };

  function getProjectsDetails(id) {
    dispatch({ isLoading: true });
    adminService
      .getProjectsDetails(token, id)
      .then((response) => {
        reduxDispatch(setProject(response.data));
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          console.log(err);
        }
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }

  function createClient(e, exist) {
    e.preventDefault();
    if (!password) {
      toast.error("Password cannot be empty");
    } else if (password.length < 6) {
      toast.error("Password must be at least 6 characters");
    } else if (!phone) {
      toast.error("Phone cannot be empty");
    } else if (!firstName) {
      toast.error("First Name cannot be empty");
    } else if (!lastName) {
      toast.error("Last Name cannot be empty");
    } else if (!email) {
      toast.error("Email cannot be empty");
    } else if (!address) {
      toast.error("Location cannot be empty");
    } else {
      reduxDispatch(setIsLoading(true));
      adminService
        .createClient(
          token,
          password,
          phone,
          firstName,
          lastName,
          address,
          email,
          exist
        )
        .then((res) => {
          toast.success("Client Successfully Created");
          getClients();
          reduxDispatch(hidePopup());
        })
        .catch((err) => {
          if (
            err.response.data.errors[0].msg ===
            "User account was deleted previously"
          ) {
            if (
              window.confirm(
                "This Email has already been used? Do you wish to retrieve the account"
              )
            ) {
              let exist = true;
              createClient(e, exist);
            } else {
              // User clicked "Cancel"
              // Add your code here
            }
          } else {
            toast.error(err.response.data.errors[0].msg);
          }
          // if (typeof err === "string") {
          //   toast.error(err.errors.msg);
          // } else if (Array.isArray(err)) {
          //   toast.error(err.errors[0].msg);
          // } else if (err.mess === "User account was deleted previously") {
          //   if (
          //     window.confirm(
          //       "This Email has already been used? Do you wish to retrieve the account"
          //     )
          //   ) {
          //     let exist = true;

          //     createClient(e, exist);
          //   } else {
          //     // User clicked "Cancel"
          //     // Add your code here
          //   }
          // } else {
          //   toast.error("Please try again");
          // }
        })

        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }

  function createAdmin(e, exist) {
    e.preventDefault();
    if (!password) {
      toast.error("Password cannot be empty");
    } else if (password.length < 6) {
      toast.error("Password must be at least 6 characters");
    } else if (!phone) {
      toast.error("Phone cannot be empty");
    } else if (!firstName) {
      toast.error("First Name cannot be empty");
    } else if (!lastName) {
      toast.error("Last Name cannot be empty");
    } else if (!email) {
      toast.error("Email cannot be empty");
    } else if (!role) {
      toast.error("Role cannot be empty");
    } else {
      reduxDispatch(setIsLoading(true));
      adminService
        .createAdmin(
          token,
          password,
          phone,
          firstName,
          lastName,
          email,
          role,
          exist
        )
        .then((res) => {
          toast.success("Admin Successfully Created");
          getUsers();
          reduxDispatch(hidePopup());
        })
        .catch((err) => {
          if (
            err.response.data.errors[0].msg ===
            "User account was deleted previously"
          ) {
            if (
              window.confirm(
                "This Email has already been used? Do you wish to retrieve the account"
              )
            ) {
              let exist = true;
              createClient(e, exist);
            } else {
              // User clicked "Cancel"
              // Add your code here
            }
          } else {
            toast.error(err.response.data.errors[0].msg);
          }
          // if (typeof err === "st
          // if (typeof err === "string") {
          //   toast.error(err.errors.msg);
          // } else if (Array.isArray(err)) {
          //   toast.error(err.errors[0].msg);
          // } else if (err.mess === "User account was deleted previously") {
          //   if (
          //     window.confirm(
          //       "This Email has already been used? Do you wish to retrieve the account"
          //     )
          //   ) {
          //     let exist = true;

          //     createAdmin(e, exist);
          //   } else {
          //     // User clicked "Cancel"
          //     // Add your code here
          //   }
          // } else {
          //   toast.error("Please try again");
          // }
        })
        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }

  function changePassword(e) {
    e.preventDefault();
    if (newPassword.length < 6) {
      toast.error("Password must be at least 6 characters");
    } else if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      dispatch({ isLoading: true });
      adminService
        .changePassword(token, password, newPassword, confirmPassword)
        .then((response) => {
          toast.success("Password Changed");
          reduxDispatch(hidePopup());
        })
        .catch((err) => {
          if (typeof err === "string") {
            toast.error(err.errors.msg);
          } else if (Array.isArray(err)) {
            toast.error(err.errors[0].msg);
          } else {
            toast.error("Invalid Credentials");
          }
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  }

  function createProject(e) {
    e.preventDefault();
    let form_data = new FormData();

    if (media.length > 1) {
      for (let i = 0; i < media.length; i++) {
        form_data.append("media", media[i]);
      }
    } else {
      form_data.append("media", media[0]);
    }

    !!name && form_data.append("name", name);
    !!manager && form_data.append("manager", manager);
    !!client && form_data.append("client", client);
    !!address && form_data.append("location", address);

    if (media.length === 0) {
      toast.error("Image cannot be empty");
    } else if (!name) {
      toast.error("Name cannot be empty");
    } else if (!manager) {
      toast.error("Manager cannot be empty");
    } else if (!client) {
      toast.error("Client cannot be empty");
    } else if (!address) {
      toast.error("Location cannot be empty");
    } else {
      reduxDispatch(setIsLoading(true));
      adminService
        .createProject(token, form_data)
        .then((res) => {
          toast.success("Project Successfully Created");
          getProjects();
          reduxDispatch(hidePopup());
        })
        .catch((err) => {
          if (typeof err === "string") {
            toast.error(err.errors.msg);
          } else if (Array.isArray(err)) {
            toast.error(err.errors[0].msg);
          } else {
            toast.error("Please try again");
          }
        })
        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }

  function updateActivity(e, id, setShow) {
    e.preventDefault();
    if (!status) {
      toast.error("Status cannot be empty");
    } else if (!activity) {
      toast.error("Activity cannot be empty");
    } else {
      reduxDispatch(setIsLoading(true));
      adminService
        .updateActivity(token, id, { name: activity, status })
        .then(() => {
          toast.success("Activity Updated");
          getProjectsDetails(id);
          reduxDispatch(hidePopup());
          setShow(false);
        })
        .catch((err) => {
          if (typeof err === "string") {
            toast.error(err.errors.msg);
          } else if (Array.isArray(err)) {
            toast.error(err.errors[0].msg);
          } else {
            // toast.error("Please try again");
            console.log(err);
          }
        })
        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }

  function removeActivity(e, id, item) {
    e.preventDefault();
    reduxDispatch(setIsLoading(true));

    adminService
      .removeActivity(token, id, {
        name: item?.name,
        status: item?.status,
      })
      .then(() => {
        toast.success("Activity Removed");
        getProjectsDetails(id);
        reduxDispatch(hidePopup());
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          toast.error("Please try again");
        }
      })
      .finally(() => {
        reduxDispatch(setIsLoading(false));
      });
  }

  function addMedia(e, id) {
    e.preventDefault();
    let form_data = new FormData();

    if (media.length > 1) {
      for (let i = 0; i < media.length; i++) {
        form_data.append("media", media[i]);
      }
    } else {
      form_data.append("media", media[0]);
    }

    !!name && form_data.append("name", name);
    !!manager && form_data.append("manager", manager);
    !!client && form_data.append("client", client);
    !!address && form_data.append("location", address);

    if (media.length === 0) {
      toast.error("Media cannot be empty");
    } else {
      reduxDispatch(setIsLoading(false));
      adminService
        .addMedia(token, id, form_data)
        .then((res) => {
          toast.success("Media Added");
          getProjectsDetails(id);
          reduxDispatch(hidePopup());
        })
        .catch((err) => {
          if (typeof err === "string") {
            toast.error(err.errors.msg);
          } else if (Array.isArray(err)) {
            toast.error(err.errors[0].msg);
          } else {
            toast.error("Please try again");
          }
        })
        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }

  function deleteMedia(e, projectId, item) {
    if (item?.list.length === 1) {
      toast.error("Must have at least 1 Media");
    } else {
      e.preventDefault();
      reduxDispatch(setIsLoading(true));
      adminService
        .deleteMedia(token, item?.id)
        .then((res) => {
          toast.success("Media Deleted");
          getProjectsDetails(projectId);
          reduxDispatch(hidePopup());
        })
        .catch((err) => {
          if (typeof err === "string") {
            toast.error(err.errors.msg);
          } else if (Array.isArray(err)) {
            toast.error(err.errors[0].msg);
          } else {
            toast.error("Please try again");
          }
        })
        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }

  function editManager(e, id) {
    e.preventDefault();
    dispatch({ isLoading: true });
    adminService
      .editManager(token, id, manager)
      .then((res) => {
        toast.success("Manager Updated");
        getProjectsDetails(id);
        reduxDispatch(hidePopup());
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          toast.error("Please try again");
        }
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }
  function manageProgress(e, id) {
    e.preventDefault();
    dispatch({ isLoading: true });
    adminService
      .manageProgress(token, id, progress)
      .then((res) => {
        toast.success("Progress Updated");
        getProjectsDetails(id);
        reduxDispatch(hidePopup());
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          toast.error("Please try again");
        }
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }

  function deleteClient(e, item) {
    e.preventDefault();
    reduxDispatch(setIsLoading(true));
    adminService
      .deleteClient(
        token,
        item.id,
        item.password,
        item.phone,
        item.firstName,
        item.lastName,
        item.address,
        item.email
      )
      .then((res) => {
        toast.success("Client Deleted");
        getClients();
        reduxDispatch(hidePopup());
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          toast.error("Please try again");
        }
      })
      .finally(() => {
        reduxDispatch(setIsLoading(false));
      });
  }

  function removeProject(e, id) {
    e.preventDefault();
    reduxDispatch(setIsLoading(true));
    adminService
      .removeProject(token, id)
      .then((res) => {
        toast.success("Project Deleted");
        navigate(-1);
        reduxDispatch(hidePopup());
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          toast.error("Please try again");
        }
      })
      .finally(() => {
        reduxDispatch(setIsLoading(false));
      });
  }
  function removeUser(e, id) {
    e.preventDefault();
    reduxDispatch(setIsLoading(true));
    adminService
      .removeUser(token, id)
      .then((res) => {
        toast.success("User Deleted");
        getUsers();
        reduxDispatch(hidePopup());
      })
      .catch((err) => {
        if (typeof err === "string") {
          toast.error(err.errors.msg);
        } else if (Array.isArray(err)) {
          toast.error(err.errors[0].msg);
        } else {
          toast.error("Please try again");
        }
      })
      .finally(() => {
        reduxDispatch(setIsLoading(false));
      });
  }

  function updateMe(e) {
    e.preventDefault();
    if (!phone) {
      toast.error("Phone cannot be empty");
    } else if (!firstName) {
      toast.error("First Name cannot be empty");
    } else if (!lastName) {
      toast.error("Last Name cannot be empty");
    } else {
      reduxDispatch(setIsLoading(true));
      adminService
        .updateMe(token, firstName, lastName, phone)
        .then((response) => {
          toast.success("Profile Updated");
          getUsers();
          reduxDispatch(hidePopup());
        })
        .catch((error) => {
          toast.error("Please try again");
        })
        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }
  function updateProfile(e, id) {
    e.preventDefault();
    if (!phone) {
      toast.error("Phone cannot be empty");
    } else if (!firstName) {
      toast.error("First Name cannot be empty");
    } else if (!lastName) {
      toast.error("Last Name cannot be empty");
    } else if (!role) {
      toast.error("Role cannot be empty");
    } else {
      reduxDispatch(setIsLoading(true));
      adminService
        .updateProfile(token, id, firstName, lastName, phone, role)
        .then((response) => {
          toast.success("Profile Updated");
          getUsers();
          reduxDispatch(hidePopup());
        })
        .catch((error) => {
          toast.error("Please try again");
        })
        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }

  function edit(e, id) {
    e.preventDefault();
    if (!phone) {
      toast.error("Phone cannot be empty");
    } else if (!firstName) {
      toast.error("First Name cannot be empty");
    } else if (!lastName) {
      toast.error("Last Name cannot be empty");
    } else if (!role) {
      toast.error("Role cannot be empty");
    } else {
      reduxDispatch(setIsLoading(true));
      adminService
        .updateProfile(token, id, firstName, lastName, phone, role)
        .then((response) => {
          toast.success("Profile Updated");
          getUsers();
          reduxDispatch(hidePopup());
        })
        .catch((error) => {
          toast.error("Please try again");
        })
        .finally(() => {
          reduxDispatch(setIsLoading(false));
        });
    }
  }

  // useEffect(() => {
  //   getProjects();
  // }, [batch]);

  useEffect(() => {
    getUsers();
    getClients();
  }, [filter, batch]);

  return {
    state,
    dispatch,
    getProjects,
    getClients,
    getProjectsDetails,
    createClient,
    createAdmin,
    changePassword,
    getUsers,
    createProject,
    refactoredClients,
    refactoredManagers,
    updateActivity,
    addMedia,
    deleteMedia,
    editManager,
    manageProgress,
    deleteClient,
    handleFilter,
    removeProject,
    removeUser,
    removeActivity,
    updateMe,
    updateProfile,
  };
}

export default useAdminFunction;
