import env from "../env";
import { request } from "./api";

const API_URL = `${env.API_URL}`;

const URL = {
  getProjects: (batch, limit) =>
    `${API_URL}/client/project?batch=${batch}&limit=${limit}`,
  getProjectsDetails: `${API_URL}/client/project`,
  changePassword: `${API_URL}/client/password`,
};

const getProjects = (token, batch, limit = 30) => {
  return request(URL.getProjects(batch, limit), {}, "GET", `Bearer ${token}`);
};
const getProjectsDetails = (token, id) => {
  return request(
    `${URL.getProjectsDetails}/${id}`,
    {},
    "GET",
    `Bearer ${token}`
  );
};
const addComment = (token, id, comment) => {
  return request(
    `${URL.getProjectsDetails}/${id}`,
    { comment },
    "POST",
    `Bearer ${token}`
  );
};
const changePassword = (
  token,
  currentPassword,
  newPassword,
  confirmPassword
) => {
  return request(
    URL.changePassword,
    { currentPassword, newPassword, confirmPassword },
    "PATCH",
    `Bearer ${token}`
  );
};

const clientService = {
  getProjects,
  getProjectsDetails,
  changePassword,
  addComment,
};

export default clientService;
