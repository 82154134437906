import React from "react";
import "./ManageProgress.css";
import CustomButton from "../../general/button/button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useAdminFunction from "../../../pages/admin/hooks/useAdminFunction";

function ManageProgress({ project, id, level }) {
  const { state, dispatch, manageProgress } = useAdminFunction();
  const { progress, isLoading } = state;

  return (
    <div className="m-progress">
      <div className="header">Manage Project</div>
      <form className="mp-progress" onSubmit={(e) => manageProgress(e, id)}>
        <div className="mp">
          <CircularProgressbar
            value={progress || level}
            strokeWidth={18}
            styles={buildStyles({
              textColor: "#FF9D00",
              pathColor: "#FF9D00",
              trailColor: "#d0d0d0",
            })}
          />
          <input
            onChange={(e) => dispatch({ progress: e.target.value })}
            value={progress || level}
            type="number"
            max="100"
          />
        </div>

        <CustomButton type="submit" loading={!!isLoading}>
          Save
        </CustomButton>
      </form>
    </div>
  );
}

export default ManageProgress;
