import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Poster from "../../../assets/images/VideoPoster.png";

const DashCard = ({ url_key, url_link, progress, name, picture }) => {
  const navigate = useNavigate();

  return (
    <div className="dash-card">
      <div
        className="dash-card-box"
        onClick={() => navigate(url_link + url_key)}
      >
        <div className="dash-card-picture">
          {picture?.type.includes("image") ? (
            <img src={picture?.url} alt="card-img" />
          ) : (
            <video poster={Poster}>
              <source src={picture?.url} />
            </video>
          )}
        </div>
        <div className="dash-card-body">
          <span className="card-body-head">{name}</span>
          <div className="progress-head">
            <span className="progress">PROGRESS</span>
            <div className="progress-value">{progress}</div>
          </div>
          <div className="progress-bar">
            <motion.div
              id="mybar"
              initial={{ width: 0 }}
              whileInView={{ width: `${Number(progress)}%` }}
              transition={{ duration: 1 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashCard;
