import React from "react";
import useClient from "../../../pages/client/hooks/useClient";
import CustomButton from "../../general/button/button";
import "./comment.css";

const CommentPop = ({ id }) => {
  const { state, dispatch, addComment } = useClient();
  const { isLoading, comment } = state;
  return (
    <div className="comment-box">
      <div className="header">Add Comment</div>
      <form onSubmit={(e) => addComment(e, id)} className="comment-form">
        <textarea
          value={comment}
          onChange={(e) => dispatch({ comment: e.target.value })}
        />
        <div className="comment-btn">
          <CustomButton type="submit" loading={!!isLoading}>
            Send Comment
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default CommentPop;
