import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoading } from "../../../redux/appSlice";
import { hidePopup } from "../../../redux/modalSlice";
import CustomButton from "../../general/button/button";
import "./WarningMe.styles.css";

function Warning({ id, fnc, item }) {
  const reduxDispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);

  return (
    <div className="warning-box">
      <div className="warning-text">Are you sure ?</div>
      <div className="btn-holder">
        <CustomButton onClick={() => reduxDispatch(hidePopup())}>
          No
        </CustomButton>
        <CustomButton loading={!!isLoading} onClick={(e) => fnc(e, id, item)}>
          Yes
        </CustomButton>
      </div>
    </div>
  );
}

export default Warning;
