import React from "react";
import { useEffect } from "react";
import "./footer.css";

const Footer = () => {
  useEffect(() => {
    body.classList.add("scroll-down");
  });
  const body = document.body;
  let lastScroll = 0;

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;

    if (currentScroll <= 0) {
      body.classList.add("scroll-down");
    }

    if (currentScroll < lastScroll && !body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-up");
      body.classList.add("scroll-down");
    }

    if (currentScroll > lastScroll && body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-down");
      body.classList.add("scroll-up");
    }

    lastScroll = currentScroll;
  });

  return (
    <div className="footer">
      <div className="footer-box">
        <div className="footer-content">
          Copyright © 2023 SABC Limited. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
