import env from "../env";
import { request } from "./api";
import axios from "axios";

const API_URL = `${env.API_URL}`;

const URL = {
  clientLogin: `${API_URL}/auth/client/login`,
  forgotPassword: `${API_URL}/auth/client/password`,
  getProfile: `${API_URL}/auth/client/me`,
  updateUser: `${API_URL}/auth/client`,
  verifyEmail: `${API_URL}/auth/admin/verify`,
  resendCode: `${API_URL}/auth/admin/resend`,
  adminLogin: `${API_URL}/auth/admin/login`,
  adminResetPassword: `${API_URL}/auth/admin/password`,
  getAdminProfile: `${API_URL}/admin/me`,
};

const clientLogin = (email, password) => {
  return request(URL.clientLogin, { email, password }, "POST");
  // return axios({
  //   method: "POST",
  //   url: URL.clientLogin,
  //   data: { email, password },
  // });
};
const getProfile = (email, password, token) => {
  return request(URL.getProfile, { email, password }, "GET", `Bearer ${token}`);
};
const getAdminProfile = (email, password, token) => {
  return request(
    URL.getAdminProfile,
    { email, password },
    "GET",
    `Bearer ${token}`
  );
};
const forgotPassword = (email) => {
  return request(URL.forgotPassword, { email }, "POST");
};
const resetPassword = (token, password, confirmPassword, email) => {
  return request(
    URL.forgotPassword,
    { token, password, confirmPassword, email },
    "PATCH"
  );
};
const adminLogin = (email, password) => {
  return request(URL.adminLogin, { email, password }, "POST");
};
const adminForgotPassword = (email) => {
  return request(URL.adminResetPassword, { email }, "POST");
};
const adminResetPassword = (token, password, confirmPassword, email) => {
  return request(
    URL.adminResetPassword,
    { token, password, confirmPassword, email },
    "PATCH"
  );
};
const verifyEmail = (email, code, token) => {
  return request(URL.verifyEmail, { email, code }, "POST", `Bearer ${token}`);
};
const resendCode = (email, token) => {
  return request(URL.resendCode, { email }, "POST", `Bearer ${token}`);
};
const updateUser = (firstName, lastName, phone, token) => {
  return request(
    URL.updateUser,
    { firstName, lastName, phone },
    "PATCH",
    `Bearer ${token}`
  );
};

const authService = {
  clientLogin,
  getProfile,
  adminLogin,
  forgotPassword,
  resetPassword,
  adminForgotPassword,
  adminResetPassword,
  verifyEmail,
  resendCode,
  updateUser,
  getAdminProfile,
};
export default authService;
