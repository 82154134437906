import React from "react";
import useAuthentication from "../../../pages/client/hooks/useAuthentication";
import CustomButton from "../../general/button/button";
import { FormInput } from "../../general/form-input/form-input";
import "./forgot-password.css";

const ForgotPassword = () => {
  const { state, handleForgotPassword, dispatch } = useAuthentication();
  const { resetEmail, isLoading } = state;

  return (
    <div className="forgot-password-section">
      <span className="password-header">Hi, Forgot your Password?</span>
      <span className="password-sub-head">
        Enter the email you've registered with. <br /> We'll send you the
        password reset instructions.
      </span>
      <form a onSubmit={handleForgotPassword} className="login-form">
        <FormInput
          label="Email Address"
          name="email"
          value={resetEmail}
          handleChange={(e) => dispatch({ resetEmail: e.target.value })}
          type="email"
        />

        <CustomButton type="submit" loading={!!isLoading}>
          Reset Password
        </CustomButton>
      </form>
    </div>
  );
};

export default ForgotPassword;
