import React from "react";
import "./button.css";

const CustomButton = ({ children, disabled, loading, ...otherProps }) => (
  <button className={disabled ? "button-disabled" : "button"} {...otherProps}>
    <div className={loading ? "button--loading" : ""}>
      <span className="button__text">{children}</span>
    </div>
  </button>
);

export default CustomButton;
