import React, { useEffect, useRef } from "react";
import "./projectDetails.css";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ListSkeleton } from "../../../components/general/cardSkeleton";
import usePopup from "../../../components/general/Popup/usePopup";
import ReactPlayer from "react-player";
import useClient from "../hooks/useClient";
import { useSelector } from "react-redux";
import { selectProject } from "../../../redux/appSlice";
import Poster from "../../../assets/images/VideoPoster.jpg";

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [index, setIndex] = useState(0);

  const { getProjectsDetails, state } = useClient();
  const { isLoading } = state;

  const { handleComment } = usePopup();

  const scrollContainer = useRef(null);

  const scrollLeft = () => {
    scrollContainer.current.scrollLeft -= 100;
  };

  const scrollRight = () => {
    scrollContainer.current.scrollLeft += 100;
  };

  const handleTab = (index) => {
    setIndex(index);
  };

  const project = useSelector(selectProject);

  useEffect(() => {
    document.title = "SABC | Project_Details";
    getProjectsDetails(projectId);
  }, []);

  function toggleFullscreen() {
    let elem = document.querySelector(".display");

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  }

  const handleStatus = (status) => {
    if (status === "completed") {
      return "completed";
    } else if (status === "in_progress") {
      return "ongoing";
    } else {
      return "yet";
    }
  };

  const progress = parseInt(project?.progress);

  const arrayList = project?.media || [];

  function capitalizeFirstLetter(string) {
    const split = string.replace("_", " ");
    return split.charAt(0).toUpperCase() + split.slice(1);
  }

  return (
    <div className="details-page">
      <div className="det-page-content">
        <div className="det-page-header">
          <div className="det-header-box">
            <div className="head">
              <span>{project?.projectName}</span>
              <span className="sub">{project?.location}</span>
            </div>
            <div className="progress">
              <div style={{ width: 100, height: 100 }}>
                <CircularProgressbar
                  value={progress || 0}
                  text={`${progress || 0}%`}
                  strokeWidth={18}
                  styles={buildStyles({
                    textColor: "white",
                    pathColor: "#FF9D00",
                    trailColor: "white",
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="page-body-box">
            <div className="project-picture">
              <div className="lg-img">
                {!isLoading ? (
                  arrayList[index]?.type.includes("image") ? (
                    <img
                      src={arrayList[index].url}
                      className="display"
                      alt="lg"
                      onClick={toggleFullscreen}
                    />
                  ) : (
                    <ReactPlayer
                      url={arrayList[index]?.url}
                      width="100%"
                      height="100%"
                      controls
                      poster={Poster}
                      light={Poster}
                    />
                  )
                ) : (
                  <Skeleton height={400} />
                )}
              </div>
              <div className="img-scroll">
                <div className="scroll" id="scroll" ref={scrollContainer}>
                  {isLoading ? (
                    <Skeleton height={124} />
                  ) : (
                    arrayList?.map((item, idx) => {
                      const y = "video";
                      const type = JSON.stringify(item?.type);
                      const typeItem = type?.slice(1, 6);

                      const two = typeItem === y;

                      return (
                        <div
                          key={idx}
                          className={
                            "sm-img" + (idx === index ? " sm-img-active" : "")
                          }
                          onClick={() => handleTab(idx)}
                        >
                          {two ? (
                            item.type ? (
                              <video poster={Poster}>
                                <source src={item.url} />
                              </video>
                            ) : (
                              // <ReactPlayer
                              //   url={item.url}
                              //   style={{
                              //     backgroundImage: `url(${Poster})`,
                              //     backgroundSize: "cover",
                              //     backgroundPosition: "center",

                              //     objectFit: "contain",
                              //   }}
                              //   width="100%"
                              //   height="100%"
                              //   poster={Poster}
                              //   playing={false}
                              // />
                              <video poster={Poster}>
                                <source src={item} />
                              </video>
                            )
                          ) : item.type ? (
                            <img src={item.url} alt="sm" />
                          ) : (
                            <img src={item} alt="sm" />
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
                <div className="scroll-left" onClick={scrollLeft}>
                  &#10094;
                </div>
                <div className="scroll-right" onClick={scrollRight}>
                  &#10095;
                </div>
              </div>
            </div>
            <div className="project-activity">
              <div className="activity-head">
                <div className="p-manager">
                  Project Manager{" "}
                  <span className="break">
                    <br />
                  </span>
                  <span>
                    - {project?.manager?.firstName} {project?.manager?.lastName}
                  </span>
                </div>
                <div
                  className="p-comment"
                  onClick={() => handleComment(projectId)}
                >
                  Add Comment
                </div>
              </div>
              <div className="activity-body">
                <div className="activity-display">
                  {isLoading ? (
                    <ListSkeleton lists={6} />
                  ) : project?.activities?.length ? (
                    project?.activities?.map((itm, index) => {
                      const stat = itm.status;
                      const stats = capitalizeFirstLetter(stat);

                      return (
                        <div className="p-activity" key={index}>
                          <div className="activity-key">{itm.name}</div>
                          <div className={handleStatus(itm.status)}>
                            {stats}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-activity">No Activity Created</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
