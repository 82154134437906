export const capitalizeFirst = (string) => {
  const lowercase = string.toLowerCase();
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export function getToken() {
  const token = sessionStorage.getItem("token");

  if (token) return token;
  else return null;
}

export function getUser() {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const setToken = (token) => {
  sessionStorage.removeItem("token");
  sessionStorage.setItem("token", token);
};

export function setUserSession(token, user) {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
}

export function setUser(user) {
  sessionStorage.removeItem("user");
  sessionStorage.setItem("user", JSON.stringify(user));
}

export function removeUserSession() {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
}

let timeoutId;

export function debounceHandler(func, delay) {
  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = setTimeout(func, delay);
}
