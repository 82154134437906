import React from "react";

import "./login.css";
import Login from "../../../components/client/login/login";
import LogoImage from "../../../assets/images/logo.png";
import { useEffect } from "react";
import BasicSlider from "../../../components/general/basicSlider/basicSlider";

const LoginPage = () => {
  useEffect(() => {
    document.title = "SABC | Login";
  }, []);

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="content">
          <div className="content-logo">
            <img src={LogoImage} alt="logo" />
          </div>
          <div className="content-body">
            <Login />
          </div>
        </div>
        <BasicSlider />
      </div>
    </div>
  );
};

export default LoginPage;
