import React from "react";
import "./change-password.css";
import CustomButton from "../../general/button/button";
import { FormPassInput } from "../../general/form-input/form-input";
import { useState } from "react";
import useClient from "../../../pages/client/hooks/useClient";

const ChangePassword = () => {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  const { state, dispatch, changePassword } = useClient();
  const { password, newPassword, confirmPassword, isLoading } = state;

  return (
    <div className="pass-box">
      <div className="pass-header">Create New Password</div>
      <form onSubmit={changePassword} className="pass-form">
        <FormPassInput
          label="Current Password"
          type={visible ? "text" : "password"}
          show={visible}
          setShow={setVisible}
          value={password}
          handleChange={(e) => dispatch({ password: e.target.value })}
        />
        <FormPassInput
          label="New Password"
          type={visible2 ? "text" : "password"}
          show={visible2}
          setShow={setVisible2}
          value={newPassword}
          handleChange={(e) => dispatch({ newPassword: e.target.value })}
        />
        <FormPassInput
          label="Confirm New Password"
          type={visible3 ? "text" : "password"}
          show={visible3}
          setShow={setVisible3}
          value={confirmPassword}
          handleChange={(e) => dispatch({ confirmPassword: e.target.value })}
        />
        <CustomButton type="submit" loading={!!isLoading}>
          Save Changes
        </CustomButton>
      </form>
    </div>
  );
};

export default ChangePassword;
