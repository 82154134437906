import React from "react";
import CustomButton from "../../general/button/button";
import useAdminFunction from "../../../pages/admin/hooks/useAdminFunction";
import DropDown from "../../general/DropDown";

function EditManager({ id }) {
  const { refactoredManagers, state, dispatch, editManager } =
    useAdminFunction();
  const { isLoading } = state;

  const handleManagers = (value) => {
    dispatch({ manager: value });
  };

  return (
    <div className="m-progress">
      <div className="header">Edit Project Manager</div>
      <form className="mp-progress" onSubmit={(e) => editManager(e, id)}>
        <div className="manager-edit">
          <DropDown onChange={handleManagers} options={refactoredManagers} />
        </div>

        <CustomButton type="submit" loading={!!isLoading}>
          Save
        </CustomButton>
      </form>
    </div>
  );
}

export default EditManager;
