import React, { useState } from "react";
import "./navbar.css";
import Logo from "../../../assets/images/logo.png";
import CustomButton from "../../general/button/button";
import { useNavigate } from "react-router-dom";
import { CgMenuRight } from "react-icons/cg";
import { IoCloseCircleOutline } from "react-icons/io5";
import usePopup from "../../general/Popup/usePopup";

import { toast } from "react-toastify";
import { removeUserSession } from "../../../utils/helpers";

const Navbar = () => {
  const navigate = useNavigate();

  const { handleMyAccount } = usePopup();

  const openAboutTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  function toggleMenu() {
    var menuToggle = document.querySelector(".nav-open");
    var navigation = document.querySelector(".nav-content");
    menuToggle.classList.toggle("active");
    navigation.classList.toggle("active");
  }

  return (
    <div className="nav-bar">
      <div className="nav-box">
        <div className="nav-logo" onClick={() => navigate("/dashboard")}>
          <img src={Logo} alt="logo" />
        </div>
        <div className="nav-content">
          <ul>
            <li
              className={
                window.location.pathname === "/dashboard" ? "li-active" : ""
              }
              onClick={() => {
                navigate("/dashboard");
                toggleMenu();
              }}
            >
              Dashboard
            </li>
            <li
              onClick={() => {
                openAboutTab("https://sabcltd.com/about/");
                toggleMenu();
              }}
            >
              About SABC
            </li>
            <li
              onClick={() => {
                handleMyAccount();
                toggleMenu();
              }}
            >
              My Account
            </li>
          </ul>
          <CustomButton
            onClick={() => {
              navigate("/");
              removeUserSession();
              toast("Byeee");
            }}
          >
            Logout
          </CustomButton>
          <div className="nav-close" onClick={() => toggleMenu()}>
            <IoCloseCircleOutline />
          </div>
        </div>
        <div className="nav-open" onClick={() => toggleMenu()}>
          <CgMenuRight />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
