import React from "react";
import "../login/login.css";
import LogoImage from "../../../assets/images/logo.png";
import ForgotPassword from "../../../components/client/forgot-password/forgot-password";
import { useEffect } from "react";
import BasicSlider from "../../../components/general/basicSlider/basicSlider";

const ForgotPage = () => {
  useEffect(() => {
    document.title = "SABC | Forgot_Password";
  }, []);

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="content">
          <div className="content-logo">
            <img src={LogoImage} alt="logo" />
          </div>
          <div className="content-body">
            <ForgotPassword />
          </div>
        </div>
        <BasicSlider />
      </div>
    </div>
  );
};

export default ForgotPage;
