import React, { useEffect } from "react";
import "../login/login.css";
import LogoImage from "../../../assets/images/logo.png";
import ResetPassword from "../../../components/admin/reset-password/reset-password";

const AdminResetPage = () => {
  useEffect(() => {
    document.title = "SABC | Admin_Password_Reset";
  }, []);

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="content">
          <div className="content-logo">
            <img src={LogoImage} alt="logo" />
          </div>
          <div className="content-body">
            <ResetPassword />
          </div>
        </div>
        <div className="image"></div>
      </div>
    </div>
  );
};

export default AdminResetPage;
