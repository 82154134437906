import { createSlice } from "@reduxjs/toolkit";

export const activitySlice = createSlice({
  name: "activityList",
  initialState: {
    activityList: [],
    progress: null,
  },

  reducers: {
    populateActivity: (state, action) => {
      state.activityList = action.payload;
    },
    addActivity: (state, action) => {
      state.activityList = [...state.activityList, action.payload];
    },
    removeActivity: (state, action) => {
      const selectedAct = state.activityList.find(
        (act) => act.task === action.payload.task
      );
      state.activityList = state.activityList.filter(
        (item) => item !== selectedAct
      );
    },
    editActivity: (state, action) => {
      let selectedAct = state.activityList.find(
        (act) => act.task === action.payload.task
      );

      selectedAct.status = action.payload.status;
    },

    editProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
});

export const {
  populateActivity,
  addActivity,
  removeActivity,
  editActivity,
  editProgress,
} = activitySlice.actions;

export const selectActivityList = (state) => state.activityList;
export const selectProgress = (state) => state.activityList.progress;

export default activitySlice.reducer;
