import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  selectProfile,
  setProject,
  setProjects,
  setTotalProjects,
} from "../../../redux/appSlice";
import { hidePopup } from "../../../redux/modalSlice";
import authService from "../../../services/auth.service";
import clientService from "../../../services/client.service";
import { getToken, getUser } from "../../../utils/helpers";
import useAuthentication from "./useAuthentication";

function useClient() {
  const reduxDispatch = useDispatch();
  const initState = {
    isLoading: false,
    id: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    comment: "",
    batch: 1,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initState
  );

  const {
    password,
    newPassword,
    confirmPassword,
    firstName,
    lastName,
    phone,
    comment,
    batch,
  } = state;
  const token = getToken();

  const { getProfile } = useAuthentication();

  function getProjects() {
    dispatch({ isLoading: true });
    clientService
      .getProjects(token, batch)
      .then((response) => {
        reduxDispatch(setProjects(response.data.data.slice().reverse()));
        reduxDispatch(setTotalProjects(response.data.total));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }
  function getProjectsDetails(id) {
    dispatch({ isLoading: true });
    clientService
      .getProjectsDetails(token, id)
      .then((response) => {
        reduxDispatch(setProject(response.data));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }
  function changePassword(e) {
    e.preventDefault();
    if (newPassword < 6) {
      toast.error("Password must be at least 6 characters");
    } else if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      dispatch({ isLoading: true });
      clientService
        .changePassword(token, password, newPassword, confirmPassword)
        .then((response) => {
          toast.success("Password Changed");
          reduxDispatch(hidePopup());
        })
        .catch((error) => {
          console.log(error);
          toast.error("Invalid Credentials");
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  }

  function updateUser(e) {
    e.preventDefault();
    dispatch({ isLoading: true });
    authService
      .updateUser(firstName, lastName, phone, token)
      .then((response) => {
        toast.success("Profile Updated");
        getProfile(token);
        reduxDispatch(hidePopup());
      })
      .catch((error) => {
        toast.error(error);
        console.log(error);
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }

  function addComment(e, id) {
    e.preventDefault();
    dispatch({ isLoading: true });
    clientService
      .addComment(token, id, comment)
      .then((response) => {
        toast.success("Comment Sent");
        reduxDispatch(hidePopup());
      })
      .catch((error) => {
        toast.error("error");
        console.log(error);
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }

  useEffect(() => {
    getProjects();
  }, [batch]);

  return {
    state,
    dispatch,
    getProjects,
    getProjectsDetails,
    changePassword,
    updateUser,
    addComment,
  };
}

export default useClient;
