import React from "react";
import CustomButton from "../../general/button/button";
import { FormProfileInput } from "../../general/form-input/form-input";
import "./new-client.css";
import { useSelector } from "react-redux";
import useAdminFunction from "../../../pages/admin/hooks/useAdminFunction";
import { selectIsLoading } from "../../../redux/appSlice";

const NewClient = () => {
  const { createClient, state, dispatch } = useAdminFunction();
  const { password, phone, firstName, lastName, address, email, reCall } =
    state;

  const isLoading = useSelector(selectIsLoading);

  return (
    <div className="nc-box">
      <div className="header"></div>

      <form onSubmit={createClient} className="nc-form">
        <div className="sm">
          <FormProfileInput
            label="First Name"
            className="sm"
            placeholder="First Name"
            value={firstName}
            handleChange={(e) => dispatch({ firstName: e.target.value })}
          />
          <FormProfileInput
            label="Last Name"
            className="sm"
            placeholder="Last Name"
            value={lastName}
            handleChange={(e) => dispatch({ lastName: e.target.value })}
          />
        </div>

        <FormProfileInput
          label="Email Address"
          placeholder="Email Address"
          type="email"
          value={email}
          handleChange={(e) => dispatch({ email: e.target.value })}
        />
        <FormProfileInput
          label="Phone Number *"
          placeholder="Phone Number"
          value={phone}
          handleChange={(e) => dispatch({ phone: e.target.value })}
        />
        <FormProfileInput
          label="Password"
          placeholder="Password"
          value={password}
          handleChange={(e) => dispatch({ password: e.target.value })}
        />
        <FormProfileInput
          label="Address"
          placeholder="Address"
          value={address}
          handleChange={(e) => dispatch({ address: e.target.value })}
        />

        <CustomButton type="submit" loading={isLoading}>
          Create New Client
        </CustomButton>
      </form>
    </div>
  );
};

export default NewClient;
