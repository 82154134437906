import React, { useState } from "react";
import CustomButton from "../../general/button/button";
import { FormProfileInput } from "../../general/form-input/form-input";

import Dropdown from "react-dropdown";
import useAdminFunction from "../../../pages/admin/hooks/useAdminFunction";
import { roles } from "../../../pages/admin/hooks/constants";
import { selectIsLoading } from "../../../redux/appSlice";
import { useSelector } from "react-redux";
import { getUser } from "../../../utils/helpers";
import { useEffect } from "react";

const AdminAccount = ({ isUser, item }) => {
  const { createAdmin, state, dispatch, updateMe, updateProfile } =
    useAdminFunction();
  const { password, phone, firstName, lastName, role, email } = state;
  const isLoading = useSelector(selectIsLoading);
  const userProfile = getUser();

  useEffect(() => {
    if (!isUser) {
      dispatch({
        firstName: item?.firstName,
        lastName: item?.lastName,
        phone: item?.phone,
        email: item?.email,
        role: item?.role,
      });
    }
  }, []);

  const isDisabled =
    userProfile?.role === "ADMIN" || userProfile?.role === "MANAGER";

  return (
    <div className="acc-box">
      <div className="header"></div>

      <form
        onSubmit={
          isUser
            ? (e) => createAdmin(e)
            : userProfile?.id === item?.id
            ? (e) => updateMe(e)
            : (e) => updateProfile(e, item?.id)
        }
        className="acc-form"
      >
        <div className="sm">
          <FormProfileInput
            label="First Name"
            className="sm"
            placeholder="First Name"
            value={firstName}
            handleChange={(e) => dispatch({ firstName: e.target.value })}
          />
          <FormProfileInput
            label="Last Name"
            className="sm"
            placeholder="Last Name"
            value={lastName}
            handleChange={(e) => dispatch({ lastName: e.target.value })}
          />
        </div>
        <FormProfileInput
          label="Email Address"
          placeholder="Email Address"
          value={email}
          readOnly={!isUser}
          handleChange={(e) => dispatch({ email: e.target.value })}
        />
        {isUser && (
          <FormProfileInput
            label="Password"
            placeholder="Password"
            readOnly={!isUser}
            value={password}
            handleChange={(e) => dispatch({ password: e.target.value })}
          />
        )}

        <div className="drop-label">Roles</div>
        {isUser && (
          <Dropdown
            controlClassName="dropDown-input"
            arrowClassName="dropDownArrow"
            menuClassName="Dropdown-Menu"
            options={roles}
            onChange={(e) => dispatch({ role: e.value })}
            value={role}
            placeholder=""
          />
        )}
        {!isUser && (
          <Dropdown
            controlClassName="dropDown-input"
            arrowClassName="dropDownArrow"
            menuClassName="Dropdown-Menu"
            options={roles}
            onChange={(e) => dispatch({ role: e.value })}
            value={role}
            placeholder=""
            disabled={isDisabled}
          />
        )}

        <FormProfileInput
          label="Phone Number *"
          placeholder="Phone Number"
          value={phone}
          handleChange={(e) => dispatch({ phone: e.target.value })}
        />
        <CustomButton type="submit" loading={!!isLoading}>
          {isUser ? "Create New Member" : "Save Changes"}
        </CustomButton>
      </form>
    </div>
  );
};

export default AdminAccount;
