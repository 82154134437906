import React, { useEffect } from "react";
import "../login/login.css";
import LogoImage from "../../../assets/images/logo.png";
import ForgotPassword from "../../../components/admin/forgot-password/forgot-password";

const AdminForgotPage = () => {
  useEffect(() => {
    document.title = "SABC | Admin_Forgot_Password";
  }, []);

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="content">
          <div className="content-logo">
            <img src={LogoImage} alt="logo" />
          </div>
          <div className="content-body">
            <ForgotPassword />
          </div>
        </div>
        <div className="image"></div>
      </div>
    </div>
  );
};

export default AdminForgotPage;
