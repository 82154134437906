import React from "react";
import "./navbar.css";
import Logo from "../../../assets/images/logo.png";
import CustomButton from "../../general/button/button";

import { useNavigate } from "react-router-dom";
import { removeUserSession } from "../../../utils/helpers";
import { CgMenuRight } from "react-icons/cg";
import { IoCloseCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { BsFillCaretDownFill } from "react-icons/bs";
import usePopup from "../../general/Popup/usePopup";
const AdminNavbar = () => {
  const navigate = useNavigate();
  const { handleAdminChangePassword } = usePopup();
  function toggleMenu() {
    var menuToggle = document.querySelector(".nav-open");
    var navigation = document.querySelector(".nav-content");
    menuToggle.classList.toggle("active");
    navigation.classList.toggle("active");
  }

  return (
    <div className="nav-bar">
      <div className="nav-box">
        <div className="nav-logo" onClick={() => navigate("/admin/dashboard")}>
          <img src={Logo} alt="logo" />
        </div>
        <div className="nav-content">
          <ul>
            <li
              className={
                window.location.pathname === "/admin/dashboard"
                  ? "li-active"
                  : ""
              }
              onClick={() => {
                navigate("/admin/dashboard");
                toggleMenu();
              }}
            >
              Dashboard
            </li>
            <li
              className={
                window.location.pathname ===
                ("/admin/client" || "/admin/client/dashboard")
                  ? "li-active"
                  : ""
              }
              onClick={() => {
                navigate("/admin/client");
                toggleMenu();
              }}
            >
              Clients
            </li>
            <li
              className={
                window.location.pathname === "/admin/project" ? "li-active" : ""
              }
              onClick={() => {
                navigate("/admin/project");
                toggleMenu();
              }}
            >
              Projects
            </li>
            <li
              className={
                window.location.pathname === "/admin/users" ? "li-active" : ""
              }
              onClick={() => {
                navigate("/admin/users");
                toggleMenu();
              }}
            >
              Team
            </li>
          </ul>
          <span className="dropdown">
            <BsFillCaretDownFill />
            <div
              onClick={() => handleAdminChangePassword()}
              className="dropdown-content"
            >
              <span>Change Password</span>
            </div>
          </span>
          <CustomButton
            onClick={() => {
              navigate("/admin");
              removeUserSession();
              toast("Byee");
            }}
          >
            Logout
          </CustomButton>
          <div className="nav-close" onClick={() => toggleMenu()}>
            <IoCloseCircleOutline />
          </div>
        </div>
        <div className="nav-open" onClick={() => toggleMenu()}>
          <CgMenuRight />
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
