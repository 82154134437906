import React, { useEffect, useState } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Display1 from "../../../assets/images/group871.png";
import Display2 from "../../../assets/images/build-and-design.jpg";
import Display3 from "../../../assets/images/pre-construction.jpg";

function BasicSlider() {
  const displayData = [Display1, Display2, Display3];
  const [currentIndex, setCurrentIndex] = useState(0);

  const carouselInfiniteScroll = () => {
    if (currentIndex === displayData.length - 1) {
      return setCurrentIndex(0);
    }
    return setCurrentIndex(currentIndex + 1);
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     carouselInfiniteScroll();
  //   }, 5000);
  //   return () => clearInterval(interval);
  // });

  // const display = displayData[currentIndex];

  return (
    <div
      className="login-image"
      // style={{ backgroundImage: `url(${display} )` }}
    >
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={6000}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        className="carousel"
        onChange={() => carouselInfiniteScroll()}
      >
        <div className="slide">
          <img src={Display1} alt="loader" style={{ height: "100%" }} />
        </div>
        <div className="slide">
          <img src={Display2} alt="loader" style={{ height: "100%" }} />
        </div>
        <div className="slide">
          <img src={Display3} alt="loader" style={{ height: "100%" }} />
        </div>
      </Carousel>
      <div className="shape">
        <span className={currentIndex === 0 ? "shape-lg" : "shape-sm"}></span>
        <span className={currentIndex === 1 ? "shape-lg" : "shape-sm"}></span>
        <span className={currentIndex === 2 ? "shape-lg" : "shape-sm"}></span>
      </div>
    </div>
  );
}

export default BasicSlider;
