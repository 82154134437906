import React, { useEffect, useState } from "react";
import "./dashboard.css";
import DashCard from "../../../components/client/dash-card/dash-card";
import { CardSkeleton } from "../../../components/general/cardSkeleton";
import Pagination from "react-custom-pagination";
import useClient from "../hooks/useClient";
import { useSelector } from "react-redux";
import { selectProjects, selectTotalProjects } from "../../../redux/appSlice";
import EmptyState from "../../../assets/icons/emptyState.json";
import Lottie from "lottie-react";

const Dashboard = () => {
  const data = useSelector(selectProjects);
  const totalProjects = useSelector(selectTotalProjects);
  const { state, dispatch } = useClient();
  const { isLoading, batch } = state;

  const [currentPage, setCurrentPage] = useState(batch);
  const [postsPerPage] = useState(30);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (number) => {
    dispatch({ batch: number });
  };

  useEffect(() => {
    document.title = "SABC | Dashboard";

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dashboard-page">
      <div className="page-content">
        <div className="page-header">
          <div className="header-box">
            <div className="head">
              SAZAK ACRES BILOQUE COMPANY (SABC) LIMITED
            </div>
            <div className="sub">
              Through a unique combination of engineering, construction and
              design disciplines and expertise.
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="body-box">
            <div className="body-grid">
              {!!isLoading ? (
                <CardSkeleton cards={6} />
              ) : currentPosts.length > 0 ? (
                currentPosts?.map((item) => (
                  <DashCard
                    url_key={item?.id}
                    url_link="/dashboard/"
                    key={item?.id}
                    name={item?.projectName}
                    progress={item?.progress}
                    picture={item?.media[0]}
                  />
                ))
              ) : (
                <div
                  className="no-data"
                  style={{ width: "auto", marginTop: "-70px" }}
                >
                  <Lottie animationData={EmptyState} />
                  No Project Created
                </div>
              )}
            </div>
          </div>
          <div className="pagination">
            {currentPosts.length > 0 && (
              <Pagination
                totalPosts={totalProjects}
                postsPerPage={postsPerPage}
                paginate={paginate}
                view={5}
                showLast={true}
                showFirst={true}
                showIndex={true}
                bgColor="transparent"
                color="#002C5F"
                boxRadius="10%"
                boxHeight="30px"
                boxWidth="30px"
                indexbgColor="#f3f3f3"
                selectColor="#d0d0d0"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
