import React from "react";
import CustomButton from "../../general/button/button";
import "./new-activity.css";
import useAdminFunction from "../../../pages/admin/hooks/useAdminFunction";
import ReactDropdown from "react-dropdown";
import { stages } from "../../../pages/admin/hooks/constants";
import { selectIsLoading } from "../../../redux/appSlice";
import { useSelector } from "react-redux";

const NewActivity = ({ id }) => {
  const { state, dispatch, updateActivity } = useAdminFunction();
  const { activity, status } = state;
  const isLoading = useSelector(selectIsLoading);

  return (
    <div className="activity-box">
      <div className="header">Create New Activity</div>
      <form onSubmit={(e) => updateActivity(e, id)} className="activity-form">
        <div className="form-field">
          <label>Activity</label>
          <input
            name="activity"
            value={activity}
            onChange={(e) => dispatch({ activity: e.target.value })}
          />
        </div>

        <div className="form-field">
          <label>Status</label>
          <ReactDropdown
            controlClassName="d-dropDown"
            arrowClassName="d-arrow"
            menuClassName="Dropdown-Menu"
            options={stages}
            onChange={(e) => dispatch({ status: e.value })}
            value={status}
            placeholder=""
          />
        </div>

        <div className="activity-btn">
          <CustomButton type="submit" loading={isLoading}>
            Save activity
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default NewActivity;
