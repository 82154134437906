import React, { useEffect, useRef } from "react";
import "./admin-projectDetails.css";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CustomButton from "../../../components/general/button/button";
import EditButton from "../../../components/EditButton/EditButton";
// import { Link } from 'react-router-dom';

import { FiUploadCloud } from "react-icons/fi";
import { BsFillCaretDownFill } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { populate } from "../../../redux/imageSlice";
import { editProgress, populateActivity } from "../../../redux/activitySlice";

import EditActivity from "../../../components/admin/new-activity/edit-activity";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ListSkeleton } from "../../../components/general/cardSkeleton";
import usePopup from "../../../components/general/Popup/usePopup";
import ReactPlayer from "react-player";
import useAdminFunction from "../hooks/useAdminFunction";
import { selectProject } from "../../../redux/appSlice";
import { getUser } from "../../../utils/helpers";
import Poster from "../../../assets/images/VideoPoster.png";

const AdminProjectDetails = () => {
  const { projectId } = useParams();
  const [index, setIndex] = useState(0);
  const [show5, setShow5] = useState(false);
  const [actTask, setActTask] = useState("");

  const {
    handleNewActivity,
    handleNewUpload,
    handleManageProgress,
    handleEditManager,
    handleEditClientsDetails,
    handleWarning,
  } = usePopup();

  const {
    state,
    getProjectsDetails,
    deleteMedia,
    removeProject,
    removeActivity,
  } = useAdminFunction();
  const { isLoading } = state;

  const data = useSelector(selectProject);

  const dispatch = useDispatch();
  const scrollContainer = useRef(null);

  const scrollLeft = () => {
    scrollContainer.current.scrollLeft -= 100;
  };

  const scrollRight = () => {
    scrollContainer.current.scrollLeft += 100;
  };

  const project = data;
  const progress = parseInt(project?.progress);

  useEffect(() => {
    document.title = "SABC | Admin_Project_Details";
    getProjectsDetails(projectId);
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      dispatch(populate(project.media));
      dispatch(populateActivity(project.projectActivity));
      dispatch(editProgress(progress));
    }, 2000);
    return () => clearTimeout(timer);
  }, [dispatch, project?.img, project?.projectActivity]);

  var arrayList = project?.media || [];

  const handleTab = (index) => {
    setIndex(index);
  };
  function toggleFullscreen() {
    let elem = document.querySelector(".display");

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  }

  const handleStatus = (status) => {
    if (status === "completed") {
      return "completed";
    } else if (status === "in_progress") {
      return "ongoing";
    } else {
      return "yet";
    }
  };

  function capitalizeFirstLetter(string) {
    const split = string.replace("_", " ");
    return split.charAt(0).toUpperCase() + split.slice(1);
  }

  const userProfile = getUser();

  return (
    <div className="details-page">
      <div className="det-page-content">
        <div className="det-page-header">
          <div className="det-header-box">
            <div className="head">
              <span>{project?.projectName}</span>
              <div className="sub">
                <span className="">
                  {project?.client?.firstName} {project?.client?.lastName}
                </span>
                <span className="divide"></span>
                <span className="">{project?.location}</span>

                <EditButton onClick={() => handleEditClientsDetails(project)} />
              </div>
            </div>
            <div className="progress">
              <div className="cp-sub">
                Project Manager -{" "}
                <span
                  className="manager-edit"
                  onClick={() => handleEditManager(projectId)}
                >
                  Edit
                </span>
                <br />
                <br />
                {project?.manager?.firstName} {project?.manager?.lastName}
              </div>

              <div
                style={{
                  width: 100,
                  height: 100,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CircularProgressbar
                  value={progress || 0}
                  text={`${progress || 0}%`}
                  strokeWidth={18}
                  styles={buildStyles({
                    textColor: "white",
                    pathColor: "#FF9D00",
                    trailColor: "white",
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="page-body-box">
            <div className="project-picture">
              <div className="lg-img">
                {!isLoading ? (
                  arrayList[index]?.type.includes("image") ? (
                    <img
                      src={arrayList[index].url}
                      className="display"
                      alt="lg"
                      onClick={toggleFullscreen}
                    />
                  ) : (
                    <ReactPlayer
                      url={arrayList[index]?.url}
                      width="100%"
                      height="100%"
                      controls
                    />
                  )
                ) : (
                  <Skeleton height={400} />
                )}
              </div>
              <div className="img-scroll">
                <div className="scroll" id="scroll" ref={scrollContainer}>
                  {isLoading ? (
                    <Skeleton height={124} />
                  ) : (
                    arrayList?.map((item, idx) => {
                      const y = "video";
                      const type = JSON.stringify(item?.type);
                      const typeItem = type?.slice(1, 6);

                      const two = typeItem === y;

                      return (
                        <div
                          key={idx}
                          className={
                            "sm-img" + (idx === index ? " sm-img-active" : "")
                          }
                          onClick={() => handleTab(idx)}
                        >
                          {two ? (
                            item.type ? (
                              // <ReactPlayer
                              //   url={item.url}
                              //   width="100%"
                              //   height="100%"
                              //   poster={Poster}
                              //   playing={false}
                              // />
                              <video poster={Poster}>
                                <source src={item.url} />
                              </video>
                            ) : (
                              <video poster={Poster}>
                                <source src={item} />
                              </video>
                            )
                          ) : item.type ? (
                            <img src={item.url} alt="sm" />
                          ) : (
                            <img src={item} alt="sm" />
                          )}
                          <div
                            className="img-del"
                            onClick={(e) =>
                              handleWarning(
                                projectId,
                                deleteMedia,
                                (item = { id: item.id, list: arrayList })
                              )
                            }
                          >
                            <MdOutlineClose />
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
                <div className="scroll-left" onClick={scrollLeft}>
                  &#10094;
                </div>
                <div className="scroll-right" onClick={scrollRight}>
                  &#10095;
                </div>
              </div>
              <div className="upload-btns">
                <CustomButton onClick={() => handleNewUpload(projectId)}>
                  <span className="" style={{ fontSize: "22px" }}>
                    <FiUploadCloud />
                  </span>
                  Upload Image/Video
                </CustomButton>
                <CustomButton
                  onClick={() => handleManageProgress(projectId, progress)}
                >
                  Manage Project Progress
                </CustomButton>
              </div>
            </div>
            <div className="project-activity">
              <div className="activity-head-head">
                <div className="activities-head">Activities</div>
                <div className="status-head">Status</div>
                <div className="action-head"></div>
              </div>
              <div className="activity-body">
                <div className="activity-display">
                  {isLoading ? (
                    <ListSkeleton lists={8} />
                  ) : project?.activities?.length ? (
                    project?.activities?.map((itm, index) => {
                      const stat = itm.status;
                      const stats = capitalizeFirstLetter(stat);
                      const task = itm.name;

                      return (
                        <div className="ad-p-activity" key={index}>
                          {show5 ? (
                            <EditActivity
                              id={projectId}
                              setShow={setShow5}
                              task={actTask}
                            />
                          ) : (
                            ""
                          )}
                          <div className="ad-activity-key">{itm.name}</div>
                          <div className={handleStatus(itm.status)}>
                            {stats}
                          </div>
                          <div className="ad-activity-action">
                            <span className="dropdown">
                              <BsFillCaretDownFill />

                              <div className="dropdown-content">
                                <span
                                  onClick={() => {
                                    setActTask(task);
                                    setShow5(true);
                                  }}
                                >
                                  Edit
                                </span>
                                <span
                                  onClick={() =>
                                    handleWarning(
                                      projectId,
                                      removeActivity,
                                      itm
                                    )
                                  }
                                >
                                  Delete
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-activity">No Activity Created</div>
                  )}
                </div>
              </div>
              <div className="activity-btns">
                <CustomButton onClick={() => handleNewActivity(projectId)}>
                  {" "}
                  <span className="plus">
                    <AiOutlinePlus />
                  </span>{" "}
                  &nbsp; &nbsp; New Project Activity
                </CustomButton>
                {userProfile?.role !== "MANAGER" && (
                  <CustomButton
                    onClick={() => handleWarning(projectId, removeProject)}
                  >
                    Delete
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProjectDetails;
