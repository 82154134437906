import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    popup: false,
    modal: null,
  },

  reducers: {
    showPopup: (state, action) => {
      state.popup = true;
      state.modal = action.payload;
    },

    hidePopup: (state, action) => {
      state.popup = false;
      state.modal = null;
    },
  },
});

export const { showPopup, hidePopup } = modalSlice.actions;

export const selectPopup = (state) => state.modal.popup;
export const selectModal = (state) => state.modal.modal;

export default modalSlice.reducer;
