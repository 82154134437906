import React, { useEffect, useMemo, useState } from "react";
import CustomButton from "../general/button/button";
import { FormProfileInput } from "../general/form-input/form-input";
import "./EditForm.css";
import adminService from "../../services/admin.service";
import { getToken } from "../../utils/helpers";
import DropDown from "../general/DropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  selectClients,
  setClients,
  setProject,
  setTotalClients,
} from "../../redux/appSlice";
import { toast } from "react-toastify";
import { hidePopup } from "../../redux/modalSlice";

function EditForm({ project, item }) {
  const [projectName, setProjectName] = useState("");
  const [location, setLocation] = useState("");
  const [client, setClient] = useState(null);
  const [editting, setEditting] = useState(false);
  const dispatch = useDispatch();
  const clientsList = useSelector(selectClients);
  useEffect(() => {
    setLocation(project.location);
    setProjectName(project.projectName);
    setClient({
      label: project?.client.firstName + " " + project?.client.lastName,
      value: project?.id,
    });
  }, [project]);

  const refactoredClients = useMemo(
    () =>
      clientsList?.map((item) => ({
        // ...item,
        // id: item?.id,
        label: `${item?.firstName} ${item?.lastName}`,
        value: item?.id,
      })),
    [clientsList]
  );

  const manager = project?.manager.firstName + " " + project?.manager.lastName;
  // const client = project?.client.firstName + " " + project?.client.lastName;
  const media = project.media[0];
  const token = getToken();

  const projectId = project.id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const clientId = client?.value ? client?.value : client;
    try {
      setEditting(true);
      adminService
        .editProjectDetails(token, projectId, clientId, projectName, location)
        .then((res) => {
          if (res.success) {
            toast.success(res?.message);
            getProjectsDetails();
            dispatch(hidePopup());
          } else {
            toast.error(res?.message);
          }
        })
        .finally(() => {
          setEditting(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getProjectsDetails = () => {
    adminService.getProjectsDetails(token, projectId).then((res) => {
      dispatch(setProject(res.data));
    });
  };

  return (
    <div className="np-box">
      <div className="header">
        <div>Project Details</div>
      </div>
      <form onSubmit={handleSubmit} className="np-form">
        <div className="uploader">
          <img
            src={media.url}
            alt="sm"
            onChange={(e) => setProjectName(e.target.value)}
          />
        </div>
        <FormProfileInput
          label="Project Name"
          placeholder="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
        {/* <FormProfileInput
          label="Client Name"
          placeholder="Client Name"
           value={client}
          disabled
        /> */}
        <div className="drop-label">Client Name</div>
        <DropDown
          value={project?.client.firstName + " " + project?.client.lastName}
          onChange={(e) => setClient(e)}
          options={refactoredClients}
        />
        <FormProfileInput
          label="Project Manager"
          placeholder="Project Manager"
          value={manager}
          disabled
        />
        <FormProfileInput
          label="Location"
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <CustomButton loading={editting} type="submit">
          Edit Project
        </CustomButton>
      </form>
    </div>
  );
}

export default EditForm;
