import React from "react";
import "./EditButton.css";
const EditButton = ({ onClick }) => {
  return (
    <div onClick={onClick} className="edit-button">
      <i class="far fa-edit"></i>
    </div>
  );
};

export default EditButton;
